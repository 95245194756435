import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import SmallNFTDisplay from "./small_nft_display";

import timeConverter from "../services/time_converter";
import state from "../services/auctionstates";

export default function AuctionCard({ auction }) {
  return (
    <Card
      className="shadow  bg-white "
      style={{
        color: "inherit",
        textDecoration: "inherit",
        marginTop: 20,
      }}
    >
      <SmallNFTDisplay tokenId={auction.tokenIds} wid="100%" hei="auto" />
      <Card.Body>
        <Card.Text style={{ fontSize: 10 }}>
          You can buy it directly for: {auction.directBuy} AVAX
          <br /> Owner:{" "}
          <Link
            style={{ textDecoration: "inherit" }}
            to={`/address/${auction.owner}`}
          >
            {auction.owner.substring(0, 15)}...
          </Link>
          <br />
          Highest bid: {auction.highestBid} AVAX
          <br />
          End time: {timeConverter(auction.endTime)}
          <br />
          Start price: {auction.startPrice} AVAX
        </Card.Text>
        <Card.Text style={{ fontSize: 10, fontWeight: "bold" }}>
          {state[auction.auctionState]}
        </Card.Text>
        <Card.Text style={{ fontSize: 10 }}>
          {/* contractAddress:{" "}
          <a href={`auction/${auction.contractAddress}`}>
            {auction.contractAddress}
          </a> */}
          <Button as = {Link} to = {`/auction/${auction.contractAddress}`}>See the details</Button>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
