import { useState, useEffect } from "react";

import ReactLoading from "react-loading";
import {
  public_rpc_url,
  MyNFTContract,
  NFTContractAbi,
} from "../services/backenddata";
import { ethers } from "ethers";

export default function SmallNFTDisplay({ tokenId, wid, hei }) {
  const [Item, setItem] = useState(null);
  const [isLoading, setisLoading] = useState(true);

  useEffect( () => {
    async function mainy() {
      let url = public_rpc_url;
      let provider = new ethers.providers.JsonRpcProvider(url);
  
      const NFTContract = new ethers.Contract(
        MyNFTContract,
        NFTContractAbi,
        provider
      );
  
      const tokenURI = await NFTContract.tokenURI(tokenId);
      fetch(`https://gateway.pinata.cloud/ipfs/${tokenURI.substring(7)}`)
        .then((res) => res.json())
        .then((data) => setItem(data))
        .then((bla) => setisLoading(false))
        .catch((e) => console.log(e));
  
    }
    mainy();

    return () => {};
  }, [tokenId]);

  return (
    <>
      {isLoading ? (
        // <img src={spinner} width={wid} height={hei} />
        <div style={{ padding: 70 }}>
          <ReactLoading type={"spin"} color={"grey"} height={hei} width={wid} />
        </div>
      ) : (
        <>
          <img src={Item.image} width={wid} height={hei} alt="MyNFT" />
          <div style={{marginLeft:10, marginTop:10}}><h4>{Item.name}</h4></div>
        </>
      )}
    </>
  );
}
