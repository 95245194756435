import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { ethers } from "ethers";
import { confirmAlert } from "react-confirm-alert";
import {
  AVALANCHE_TESTNET_PARAMS,
} from "../services/backenddata";
async function checkMetaMask(setSignerAddress) {
  if (window.ethereum) {
    // depricated
    // await window.ethereum.enable(); // Enable the Ethereum client
    try {
      //return list of accounts
      await window.ethereum.request({
        // method: "eth_requestAccounts",
        method: "wallet_addEthereumChain",
        params: [AVALANCHE_TESTNET_PARAMS],
      });
      await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      const addr = await signer.getAddress();
      await setSignerAddress(addr);

      return true;

      
    } catch (error) {

      if (error.code === 4001) {
        // User rejected request
        confirmAlert({
          title: "Request rejected",
          message: "Please accept to connect to the dApp",
          buttons: [
            {
              label: "OK!",
              onClick: () => {},
            },
          ],
        });
      }



      
      return false;
    }
  } else {
    //user does not have Metamask

    confirmAlert({
      title: "Download Metamask",
      message: "You don't have a Crypto Wallet installed in in your browser",
      buttons: [
        {
          label: "Continue",
          onClick: () => {},
        },
        {
          label: "click here to Download Metamask",
          onClick: () =>
            window
              .open(
                "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en",
                "_blank"
              )
              .focus(),
        },
      ],
    });

    return false;
  }
}

export default checkMetaMask;
