import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Mint from "./routes/Mint";
import All from "./routes/All";
import AddressRoute from "./routes/Addressroute";
import NavBar from "./components/navbar";
import checkMetaMask from "./services/checkmetamask";
import ShowNFT from "./routes/ShowNFTroute";
import Marketplace from "./routes/Marketplace";
import ShowAuction from "./routes/ShowAuction";
import ShowAuctionsOfAddress from "./routes/ShowAuctionsOfAddress";
import FullMarketplace from "./routes/FullMarketplace";
import RoadRoute from "./routes/Roadmap";
import FAQroute from "./routes/FAQ"
import Setter from "./routes/Setter";
import Tester from "./routes/Tester";

ReactDOM.render(
  <BrowserRouter>
      <div>
        <NavBar checkMetaMask={checkMetaMask} />
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Routes>
        <Route path="/roadmap" element={<RoadRoute />} />
        <Route path="/faq" element={<FAQroute />} />
          <Route path="/address/:URLaddress" element={<AddressRoute />} />
          <Route path="id/:id" element={<ShowNFT />} />
          <Route path="auction/:contractAddress" element={<ShowAuction />} />
          <Route path="/fullmarketplace" element={<FullMarketplace />} />

          <Route
            path="auctionsofaddress/:URLaddress"
            element={<ShowAuctionsOfAddress />}
          />
          <Route path="/" element={<App />} />
          <Route path="/tester" element={<Tester />} />

          <Route path="/mint" element={<Mint />} />
          <Route path="/setter" element={<Setter />} />
          <Route path="/marketplace" element={<Marketplace />} />
          <Route path="/items" element={<All />} />

          <Route
            exact
            path="*"
            element={
              <h1 style={{marginTop: "90px"}}>
                404 <br /> No Page Founded
              </h1>
            }
          />
        </Routes>
      </div>

  </BrowserRouter>,
  document.getElementById("root")
);
