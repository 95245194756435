import Faq from "react-faq-component";

const data = {
  title: "Frequently asked questions",
  rows: [
    {
      title: "What is Circles?",
      content: `Circles is a whole ecosystem with its own NFT collection and governance token. Circles Ecosystem includes the NFT collection (Circles NFT), Governance Token (PieCoin). General marketplace (Circles Marketplace) and a decentralized autonomous organization (Circles DAO).`,
    },
    {
      title: "What is an Circles NFT?",
      content: `The first NFT project on Avalanche where users create
      their custom NFT. The cost of minting NFT corresponds with
      the circles the users put in it. There are 3,142 NFTs with
      unlimited possiblites. Each time u increase the number of
      circles in the mint process, a color is chosen from an array
      of known colors with entropy from your own device. The
      Circle is then drawn. All of this results in the final NFT.`,
    },


    {
      title: "What are the ways to mint?",
      content: `There are two ways to mint a Circles NFT. Raffle mint  or customizing mint. If you raffle you get am NFT with a random number of circles in it (1 Circle - 20 Circles).The mint costs 1 AVAX in this mehtod.
      The other way to mint Circles is to design your own NFT where you pay more AVAX to make your NFT more valubale. `,
    },
    {
      title: "How many circles do I get if I raffle mint?",
      content: `You get an NFT with (1 up to 20 circles in it). The probabilty to get 20-circle NFT is one of a thousand. However, this NFT generated 20 more yields than a 1-circle NFT. The probabilty to get a less valuable NFT is higher. To get the exact probabilties you can DM us on Twitter or ask the community on Discord`,
    },
    {
      title: "Raffle mint or customizing mint",
      content: `If you customize your own NFT, you get at least 3 circles in it and you can design it as you wish, but the cost of mint changes according to the number of circles you put in the token. If you want to raffle mint, you could get an NFT with a high value but it is possible to get an NFT with less than 3 circles. Raffle mint always costs 1 AVAX.`,
    },
    {
      title: "How many Circles NFTs are there?",
      content:
        "There will be only 3142 items. The number is inspired by Pi, mathematical constant which is approximately equal to 3.142. It is defined as the ratio of a circle's circumference to its diameter,",
    },
    {
      title: "Does the team own any Circles NFTs?",
      content:
        "We have retained 20 Circles NFTs, These will be gifted to our Hardworking Community Members, Twitter Giveaways and also our Team. The first 20 NFTs are minted BY THE TEAM",
    },
    {
      title: "What is PieCoin?",
      content: `PieCoin will be the cornerstone of Circles DAO. The holders of PieCoin vote on DAO decisions. This DAO is in charge of the Circles Marketplace. Circles Marketplace is community-driven. The PieCoin holders make the decisions and the Circles NFT holders earn the revenue from the marketplace.
      `,
    },
    {
      title: "What is the tokenmoics of PieCoin?",
      content: `Tokenomics of PieCoin: PieCoin has a capped supply of 21 million tokens. With every transaction, 2% of the value transferred gets burned 1% gets distributed to Avalanche validators. This implicated the supply will be shrinking so the token establishes itself as the store of value in avalanche ecosystem. This incentivizes the holders not to spend their tokens. At a random point in October 2022, the burn and the gift to validator will stop so the token becomes a better medium of exchange. The supply is distributed to the NFT holders during one week after the mint. 5% of the supply is used to provide liquidity on the DEXs so the token becomes tradable.`,
    },
    {
      title: "How does PieCoin get distributed?",
      content: `Three days after the mint starts, every holder of Circles NFT can stake their NFT in exchange for the first deflationary ERC20 token. The staking process lasts for a short period of 2 weeks. During these weeks the supply of 21 mio. PieCoins gets distributed to the stakers.
      `,
    },
    {
      title: "ًWhat is Circles DAO?",
      content: `Circles DAO is in charge of Circles Marketplace. DAOs are the best-known way to make sure that an oganization is keeping its members in focus. We implement a form of a marketplace where the community members and the users decide how everything is managed. 
      `,
    },
    {
      title: "How is the number of Circles calculated in a raffle mint",
      content: `There is an 80% chance to get an NFT with 4 circles or fewer, 9.9% chance to get an NFT with 5-10 circles and 0.1% chance to get an NFT with 20 circles`,
    },



 
    {
      title: "ًWhat is Circles Marketplace?",
      content: `Circles Marketplace is a community-driven marketplace. There is no single party that makes the decisions on behalf of the majority. In simple terms, it is the start of the democratization of NFTs. `,
    }
   






  ],
};

const styles = {
  //   bgColor: 'blue',
  //   titleTextColor: "blue",
  //   rowTitleColor: "blue",
  // rowContentColor: 'grey',
  arrowColor: "#6f7df6",
};

export default function FaqComponent() {
  return (
    <div style={{ padding: 30 }}>
      <Faq data={data} styles={styles} />
    </div>
  );
}
