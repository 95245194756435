import { useEffect, useRef, useState } from "react";
import { ethers } from "ethers";
import {
  MyNFTContract,
  NFTContractAbi,
  public_rpc_url,
} from "../services/backenddata";
import { confirmAlert } from "react-confirm-alert";
import { Row, Col, Container, Button } from "react-bootstrap";
import ReactLoading from "react-loading";
import ItemDisplay from "../components/item_display";
import { useParams } from "react-router-dom";
import { AVALANCHE_TESTNET_PARAMS } from "../services/backenddata";
import SendDialog from "../components/send_dialog";
import AuctionDialog from "../components/auction_dialog";
import EmptyAddressComponent from "../components/EmptyAddress";

function AddressRoute() {
  let { URLaddress } = useParams();
  const [MyAddress, setMyAddress] = useState(null);
  const [isLoading, setisLoading] = useState(true);
  const [ListOfNfts, setListOfNfts] = useState([]);
    
  const [ShowAddressInput, setShowAddressInput] = useState(false);
  const [ShowAuctionInput, setShowAuctionInput] = useState(false);

  const LastSlice = useRef(null);
  const itemId = useRef(null);



  useEffect( () => {


    async function firstFetch(startSlice, endSlice) {
      if (window.ethereum) {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [AVALANCHE_TESTNET_PARAMS],
        });
        await window.ethereum.request({
          method: "eth_requestAccounts",
        });
  
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        setMyAddress(await signer.getAddress());
      }



      //define the backend to talkt to
      let url = public_rpc_url;
      let provider = new ethers.providers.JsonRpcProvider(url);
      const contract = new ethers.Contract(
        MyNFTContract,
        NFTContractAbi,
        provider
      );
      let myIds = await contract.myItems(URLaddress);
      // formatting
      myIds = myIds.map((id) => {
        return ethers.utils.formatEther(id) * 10 ** 18;
      });

      let toLoadids = myIds.slice(startSlice, endSlice);

      //if all ids that can be laoded will be loaded this time

      if (toLoadids.length === 0) {
        confirmAlert({
          customUI: ({ onClose }) => <EmptyAddressComponent />,
          closeOnEscape: false,
          closeOnClickOutside: false,
    
        })

      } else {
        setListOfNfts([...toLoadids]);
        //if the length of the shown list is as long as the length of possible list hide the button
        if ([...toLoadids].length === myIds.length) {
        }
      }
    }

    async function mainy() {
      const startSlice = 0;
      const endSlice = 200; //how many to load
      //change reference to know the last one loaded //remember silce ignores last item in list
      LastSlice.current = endSlice;
      await firstFetch(startSlice, endSlice);
      setisLoading(false);
    }

    mainy();

    return () => {
      setListOfNfts([]);
      confirmAlert({
        customUI: ({ onClose }) => onClose(),
      });
    };
  }, [URLaddress]);

  return (
    <div style={{ marginTop: "60px" }}>
      {/* {JSON.stringify(ListOfNfts)} */}
      {ShowAddressInput ? (
        <SendDialog
          setShowAddressInput={setShowAddressInput}
          confirmAlert={confirmAlert}
          URLaddress={URLaddress}
          itemId={itemId.current}
        />
      ) : null}
      {ShowAuctionInput ? (
        <AuctionDialog
          setShowAddressInput={setShowAuctionInput}
          confirmAlert={confirmAlert}
          URLaddress={URLaddress}
          itemId={itemId.current}
        />
      ) : null}

      {isLoading ? (
        <center>
          <ReactLoading type={"bars"} color={"grey"} height={50} width={100} />
        </center>
      ) : (
        <>
          <Container>
            <Row xs={3} md={4}>
              {ListOfNfts.map((item, index) => (
                <Col key={index}>
                  <ItemDisplay tokenId={item} key={index} />
                  {/* if owner show */}
                  {MyAddress === URLaddress ? (
                    <>
                      <Button
                        onClick={(e) => {
                          // clicled on send it
                          itemId.current = parseInt(item);
                          setShowAddressInput(!ShowAddressInput);
                        }}
                        variant="secondary"
                        style={{width:"100%"}}
                      >
                        send it
                      </Button>
                    </>
                  ) : null}
                </Col>
              ))}
            </Row>
          </Container>
          <br />


          <br />
          <br />
        </>
      )}
    </div>
  );
}

export default AddressRoute;

// import { useEffect, useRef, useState } from "react";
// import { ethers } from "ethers";
// import SendDialog from "../components/send_dialog";
// import AuctionDialog from "../components/auction_dialog";
// import {
//   MyNFTContract,
//   NFTContractAbi,
//   public_rpc_url,
// } from "../services/backenddata";
// import { Row, Col, Card, Container, Button } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import { confirmAlert } from "react-confirm-alert";
// import ReactLoading from "react-loading";
// import { useParams } from "react-router-dom";
// import checkMetaMask from "../services/checkmetamask";

// function AddressRoute() {

//   let { URLaddress } = useParams();

//   const [isLoading, setisLoading] = useState(true);
//   const [ListOfNfts, setListOfNfts] = useState([]);
//   const [admin, setadmin] = useState(false);
//   const [ShowAddressInput, setShowAddressInput] = useState(false);
//   const [ShowAuctionInput, setShowAuctionInput] = useState(false);
//   const itemId = useRef(null);
//   const LastSlice = useRef(null);
//   const [ToShowLoadButton, setToShowLoadButton] = useState(true);

//   async function loadDataFromMetaUndUpdateState(startSlice, endSlice) {
//     //define the backend to talkt to
//     const provider = new ethers.providers.Web3Provider(window.ethereum);
//     const Contract = new ethers.Contract(
//       MyNFTContract,
//       NFTContractAbi,
//       provider
//     );
//     const signer = provider.getSigner();
//     const addr = await signer.getAddress();
//     if (addr === URLaddress) {
//       setadmin(signer);
//     }

//     let myIds = await Contract.myItems(URLaddress);
//     // formatting
//     myIds = myIds.map((id) => {
//       return ethers.utils.formatEther(id) * 10 ** 18;
//     });

//     if (myIds.length === 0) {
//       confirmAlert({
//         title: "This address has no NFTs ",
//         buttons: [
//           {
//             label: "Go buy some.!",
//             onClick: () => {
//               window.open("/marketplace", "_parent");
//             },
//           },
//           {
//             label: "Go to this address' Auctions.",
//             onClick: () => {
//               window.open(`/auctionsofaddress/${URLaddress}`, "_parent");
//             },
//           },
//         ],
//       });
//     }

//     let toLoadids = myIds.slice(startSlice, endSlice);

//     console.log(toLoadids);

//     console.log(toLoadids.length === 0);
//     //if all ids that can be laoded will be loaded this time

//     if (toLoadids.length === 0) {
//       setToShowLoadButton(false);
//     } else {
//       //more is needed so load more and append it to the state
//       let arr = [];
//       for (let i of toLoadids) {
//         try {
//           const last_nft_ipfs = await Contract.tokenURI(i);

//           if (last_nft_ipfs !== "ipfs://undefined") {
//             //handle only if it correct and remove ipfs://
//             let ipfs_url = `https://gateway.pinata.cloud/ipfs/${last_nft_ipfs.substring(
//               7
//             )}`;

//             await fetch(ipfs_url)
//               .then((res) => res.json())
//               .then((data) => arr.push(data))
//               .catch((e) => console.log(e));
//           }
//         } catch (ContractError) {
//           //stop pushing because everything is loaded
//         }
//       }
//       setListOfNfts([...ListOfNfts, ...arr]);
//       //if the length of the shown list is as long as the length of possible list hide the button
//       if ([...ListOfNfts, ...arr].length === myIds.length) {
//         setToShowLoadButton(false);
//       }
//     }
//   }

//   async function loadDataFromRPCUndUpdateState(startSlice, endSlice) {
//     //define the backend to talkt to
//     let url = public_rpc_url;
//     let provider = new ethers.providers.JsonRpcProvider(url);
//     const Contract = new ethers.Contract(
//       MyNFTContract,
//       NFTContractAbi,
//       provider
//     );
//     // const signer = provider.getSigner();
//     // const addr = await signer.getAddress();
//     // if (addr === URLaddress) {
//     //   setadmin(signer);
//     // }

//     let myIds = await Contract.myItems(URLaddress);
//     // formatting
//     myIds = myIds.map((id) => {
//       return ethers.utils.formatEther(id) * 10 ** 18;
//     });

//     if (myIds.length === 0) {
//       confirmAlert({
//         title: "This address has no NFTs ",
//         buttons: [
//           {
//             label: "Go buy some.!",
//             onClick: () => {
//               window.open("/marketplace", "_parent");
//             },
//           },
//         ],
//       });
//     }

//     let toLoadids = myIds.slice(startSlice, endSlice);

//     console.log(toLoadids);

//     console.log(toLoadids.length === 0);
//     //if all ids that can be laoded will be loaded this time

//     if (toLoadids.length === 0) {
//       setToShowLoadButton(false);
//     } else {
//       //more is needed so load more and append it to the state
//       let arr = [];
//       for (let i of toLoadids) {
//         try {
//           const last_nft_ipfs = await Contract.tokenURI(i);

//           if (last_nft_ipfs !== "ipfs://undefined") {
//             //handle only if it correct and remove ipfs://
//             let ipfs_url = `https://gateway.pinata.cloud/ipfs/${last_nft_ipfs.substring(
//               7
//             )}`;

//             await fetch(ipfs_url)
//               .then((res) => res.json())
//               .then((data) => arr.push(data))
//               .catch((e) => console.log(e));
//           }
//         } catch (ContractError) {
//           //stop pushing because everything is loaded
//         }
//       }
//       setListOfNfts([...ListOfNfts, ...arr]);
//       //if the length of the shown list is as long as the length of possible list hide the button
//       if ([...ListOfNfts, ...arr].length === myIds.length) {
//         setToShowLoadButton(false);
//       }
//     }
//   }

//   //load the component
//   useEffect(() => {
//     async function loadDataFromMetaUndUpdateState(startSlice, endSlice) {
//       //define the backend to talkt to
//       const provider = new ethers.providers.Web3Provider(window.ethereum);
//       const Contract = new ethers.Contract(
//         MyNFTContract,
//         NFTContractAbi,
//         provider
//       );
//       const signer = provider.getSigner();
//       const addr = await signer.getAddress();
//       if (addr === URLaddress) {
//         setadmin(signer);
//       }

//       let myIds = await Contract.myItems(URLaddress);
//       // formatting
//       myIds = myIds.map((id) => {
//         return ethers.utils.formatEther(id) * 10 ** 18;
//       });

//       if (myIds.length === 0) {
//         confirmAlert({
//           title: "This address has no NFTs ",
//           buttons: [
//             {
//               label: "Go buy some.!",
//               onClick: () => {
//                 window.open("/marketplace", "_parent");
//               },
//             },
//             {
//               label: "Go to this address' Auctions.",
//               onClick: () => {
//                 window.open(`/auctionsofaddress/${URLaddress}`, "_parent");
//               },
//             },
//           ],
//         });
//       }

//       let toLoadids = myIds.slice(startSlice, endSlice);

//       console.log(toLoadids);

//       console.log(toLoadids.length === 0);
//       //if all ids that can be laoded will be loaded this time

//       if (toLoadids.length === 0) {
//         setToShowLoadButton(false);
//       } else {
//         //more is needed so load more and append it to the state
//         let arr = [];
//         for (let i of toLoadids) {
//           try {
//             const last_nft_ipfs = await Contract.tokenURI(i);

//             if (last_nft_ipfs !== "ipfs://undefined") {
//               //handle only if it correct and remove ipfs://
//               let ipfs_url = `https://gateway.pinata.cloud/ipfs/${last_nft_ipfs.substring(
//                 7
//               )}`;

//               await fetch(ipfs_url)
//                 .then((res) => res.json())
//                 .then((data) => arr.push(data))
//                 .catch((e) => console.log(e));
//             }
//           } catch (ContractError) {
//             //stop pushing because everything is loaded
//           }
//         }
//         setListOfNfts([...arr]);
//         //if the length of the shown list is as long as the length of possible list hide the button
//         if ([...arr].length === myIds.length) {
//           setToShowLoadButton(false);
//         }
//       }
//     }

//     async function loadDataFromRPCUndUpdateState(startSlice, endSlice) {
//       //define the backend to talkt to
//       let url = public_rpc_url;
//       let provider = new ethers.providers.JsonRpcProvider(url);
//       const Contract = new ethers.Contract(
//         MyNFTContract,
//         NFTContractAbi,
//         provider
//       );
//       // const signer = provider.getSigner();
//       // const addr = await signer.getAddress();
//       // if (addr === URLaddress) {
//       //   setadmin(signer);
//       // }

//       let myIds = await Contract.myItems(URLaddress);
//       // formatting
//       myIds = myIds.map((id) => {
//         return ethers.utils.formatEther(id) * 10 ** 18;
//       });

//       if (myIds.length === 0) {
//         confirmAlert({
//           title: "This address has no NFTs ",
//           buttons: [
//             {
//               label: "Go buy some.!",
//               onClick: () => {
//                 window.open("/marketplace", "_parent");
//               },
//             },
//           ],
//         });
//       }

//       let toLoadids = myIds.slice(startSlice, endSlice);

//       console.log(toLoadids);

//       console.log(toLoadids.length === 0);
//       //if all ids that can be laoded will be loaded this time

//       if (toLoadids.length === 0) {
//         setToShowLoadButton(false);
//       } else {
//         //more is needed so load more and append it to the state
//         let arr = [];
//         for (let i of toLoadids) {
//           try {
//             const last_nft_ipfs = await Contract.tokenURI(i);

//             if (last_nft_ipfs !== "ipfs://undefined") {
//               //handle only if it correct and remove ipfs://
//               let ipfs_url = `https://gateway.pinata.cloud/ipfs/${last_nft_ipfs.substring(
//                 7
//               )}`;

//               await fetch(ipfs_url)
//                 .then((res) => res.json())
//                 .then((data) => arr.push(data))
//                 .catch((e) => console.log(e));
//             }
//           } catch (ContractError) {
//             //stop pushing because everything is loaded
//           }
//         }
//         setListOfNfts([...arr]);
//         //if the length of the shown list is as long as the length of possible list hide the button
//         if ([...arr].length === myIds.length) {
//           setToShowLoadButton(false);
//         }
//       }
//     }

//     async function mainy() {
//       if (URLaddress === "null") {
//         setisLoading(false);
//         confirmAlert({
//           title: "You need to connect your wallet with the dApp 😑",
//           buttons: [
//             {
//               label: "Go back.!",
//               onClick: () => {
//                 window.open("/mint", "_parent");
//               },
//             },
//           ],
//         });
//       } else {
//         ///number of iterms to fetch
//         const startSlice = 0;
//         const endSlice = 6; //how many to load
//         //change reference to know the last one loaded //remember silce ignores last item in list
//         LastSlice.current = endSlice;
//         if (window.ethereum) {
//           await checkMetaMask();
//           await loadDataFromMetaUndUpdateState(startSlice, endSlice);
//           setisLoading(false);
//         } else {
//           await loadDataFromRPCUndUpdateState(startSlice, endSlice);
//           setisLoading(false);
//         }
//       }
//     }
//     mainy();

//     return () => {
//       setListOfNfts([]);
//     };
//   }, [URLaddress]);

//   return (
//     <div style={{ marginTop: "60px" }}>
//       {ShowAddressInput ? (
//         <SendDialog
//           setShowAddressInput={setShowAddressInput}
//           confirmAlert={confirmAlert}
//           URLaddress={URLaddress}
//           itemId={itemId.current}
//         />
//       ) : null}
//       {ShowAuctionInput ? (
//         <AuctionDialog
//           setShowAddressInput={setShowAuctionInput}
//           confirmAlert={confirmAlert}
//           URLaddress={URLaddress}
//           itemId={itemId.current}
//         />
//       ) : null}
//       {isLoading ? (
//         <center>
//           <ReactLoading type={"bars"} color={"grey"} height={50} width={100} />
//         </center>
//       ) : (
//         <>
//           <Container>
//             <Row xs={3} md={4}>
//               {ListOfNfts.map((item, index) => (
//                 <Col key={index}>
//                   <Card
//                     className="shadow  bg-white "
//                     style={{
//                       color: "inherit",
//                       textDecoration: "inherit",
//                       marginTop: 20,
//                     }}
//                   >
//                     <Link to={"/id/" + item.name.substring(7).trim()}>
//                       <Card.Img
//                         variant="top"
//                         src={item.image}
//                         style={{ borderBottom: " solid 1px #d3d3d3" }}
//                       />
//                     </Link>
//                     <Card.Body>
//                       <Card.Title
//                         as={Link}
//                         to={"/id/" + item.name.substring(7).trim()}
//                         style={{
//                           textDecoration: "inherit",
//                           fontWeight: "bold",
//                           color: "black",
//                         }}
//                       >
//                         {item.name}
//                       </Card.Title>
//                       <Card.Text style={{ fontSize: 10 }}>
//                         {item.attributes[0].trait_type}:{" "}
//                         <span style={{ fontWeight: "bold" }}>
//                           {item.attributes[0].value}{" "}
//                         </span>
//                       </Card.Text>
//                       <Card.Text style={{ fontSize: 10 }}>
//                         {admin === false ? null : (
//                           <Button
//                             onClick={(e) => {
//                               // clicled on send it
//                               itemId.current = parseInt(
//                                 item.name.substring(7).trim()
//                               );
//                               setShowAddressInput(!ShowAddressInput);
//                             }}
//                             variant="primary"
//                           >
//                             send it
//                           </Button>
//                         )}
//                       </Card.Text>
//                       <Card.Text style={{ fontSize: 10 }}>
//                         {admin === false ? null : (
//                           <Button
//                             onClick={(e) => {
//                               // clicled on send it
//                               itemId.current = parseInt(
//                                 item.name.substring(7).trim()
//                               );
//                               setShowAuctionInput(!ShowAuctionInput);
//                             }}
//                             variant="primary"
//                           >
//                             auction it
//                           </Button>
//                         )}
//                       </Card.Text>
//                     </Card.Body>
//                   </Card>
//                 </Col>
//               ))}
//             </Row>

//             <Row>
//               <br />
//             </Row>
//           </Container>
//           <center>
//             {ToShowLoadButton ? (
//               <Button
//                 id="loadbutt"
//                 onClick={async (e) => {
//                   //I know it is crazy not to use useRef here
//                   document.getElementById("loadbutt").disabled = true;
//                   //load more and change the end slice as refrence for future
//                   let to_load = 8;
//                   if (window.ethereum) {
//                     await checkMetaMask();
//                     await loadDataFromMetaUndUpdateState(
//                       LastSlice.current,
//                       to_load
//                     );
//                   } else {
//                     await loadDataFromRPCUndUpdateState(
//                       LastSlice.current,
//                       to_load
//                     );
//                   }
//                   document.getElementById("loadbutt").disabled = false;
//                   LastSlice.current += to_load;
//                 }}
//               >
//                 Load More
//               </Button>
//             ) : null}
//           </center>
//         </>
//       )}
//     </div>
//   );
// }

// export default AddressRoute;
