import "./incrementstyles.css";
import getRandomColor from "../../services/randomcolor";
import getRandomIntInclusive from "../../services/randomnumber";





export default function IncrementInput({Circle,setListOfCircles, ListOfCircles, setEditedCircleIndex}){
    function decerement(){
        if (ListOfCircles.length >3){
            //there has to be one circle
            let new_length = ListOfCircles.length-1;
            setEditedCircleIndex(new_length-1);
            setListOfCircles([...ListOfCircles.slice(0,new_length)]);
          }
    }

    async function increment() {
        let newCircle = new Circle(
            getRandomIntInclusive(0, 500).toString(),
            getRandomIntInclusive(0, 500).toString(),
            getRandomIntInclusive(30, 100).toString(),
            getRandomColor(),
            getRandomIntInclusive(0, 10).toString(),
            getRandomColor(),
            (getRandomIntInclusive(80, 100)/100).toString()
          );
          setListOfCircles([...ListOfCircles, newCircle]);
          setEditedCircleIndex(ListOfCircles.length);
        
    }

    


    return (<>

    <label style={{marginTop:9}} htmlFor="number of circles :" className="grey-text">
        number of circle 
      </label>
      <br />
      <div className="number-input" style={{marginBottom:9}}>
        <button
        onClick={e=>{
          //decerement
          decerement();
        }}
          className="minus"
        ></button>
        <input
          className="quantity"
          min="1"
          name="quantity"
          value={ListOfCircles.length}
          type="number"
          disabled
        />
        <button
        onClick={e=>{
          //increment
          increment();
        }}
          className="plus"
        ></button>
      
      </div>

    </>);
}