import { useEffect, useRef, useState } from "react";
import { ethers } from "ethers";
import {
  MyNFTContract,
  NFTContractAbi,
  public_rpc_url,
} from "../services/backenddata";
import { Row, Col, Container, Button } from "react-bootstrap";
import ReactLoading from "react-loading";
import ItemDisplay from "../components/item_display";
function All() {
  const [isLoading, setisLoading] = useState(true);
  const [ListOfNfts, setListOfNfts] = useState([]);
  const LastSlice = useRef(null);
  const loadbutt = useRef(null);

  const [ToShowLoadButton, setToShowLoadButton] = useState(true);

  async function loadDataFromRPCUndUpdateState(startSlice, endSlice) {
    //define the backend to talkt to
    let url = public_rpc_url;
    let provider = new ethers.providers.JsonRpcProvider(url);
    const Contract = new ethers.Contract(
      MyNFTContract,
      NFTContractAbi,
      provider
    );

    let counter = await Contract._tokenCounter();
    //formatting
    counter = ethers.utils.formatEther(counter) * 10 ** 18;

    let myIds = [...Array(counter).keys()];

    myIds = myIds.reverse();
    myIds = myIds.slice(0, -1);

    let toLoadids = myIds.slice(startSlice, endSlice);

    //if all ids that can be laoded will be loaded this time

    if (toLoadids.length === 0) {
      setToShowLoadButton(false);
    } else {
      setListOfNfts([...ListOfNfts, ...toLoadids]);
      //if the length of the shown list is as long as the length of possible list hide the button
      if ([...ListOfNfts, ...toLoadids].length === myIds.length) {
        setToShowLoadButton(false);
      }
    }
  }

  useEffect(() => {
    async function firstFetch(startSlice, endSlice) {
      //define the backend to talkt to
      let url = public_rpc_url;
      let provider = new ethers.providers.JsonRpcProvider(url);
      const contract = new ethers.Contract(
        MyNFTContract,
        NFTContractAbi,
        provider
      );

      let counter = await contract._tokenCounter();
      //formatting
      
      counter = Math.floor(ethers.utils.formatEther(counter) * 10 ** 18);
      console.log(counter)

      let myIds = [...Array(counter).keys()];

      myIds = myIds.reverse();
      myIds = myIds.slice(0, -1);

      console.log(myIds)

      let toLoadids = myIds.slice(startSlice, endSlice);

      //if all ids that can be laoded will be loaded this time

      if (toLoadids.length === 0) {
        setToShowLoadButton(false);
      } else {
        setListOfNfts([...toLoadids]);
        //if the length of the shown list is as long as the length of possible list hide the button
        if ([...toLoadids].length === myIds.length) {
          setToShowLoadButton(false);
        }
      }
    }

    async function mainy() {
      const startSlice = 0;
      const endSlice = 16; //how many to load
      //change reference to know the last one loaded //remember silce ignores last item in list
      LastSlice.current = endSlice;
      await firstFetch(startSlice, endSlice);
      setisLoading(false);
    }

    mainy();

    return () => {
      setListOfNfts([]);
    };
  }, []);

  return (
    <div style={{ marginTop: "60px" }}>
      {/* {JSON.stringify(ListOfNfts)} */}

      {isLoading ? (
        <center>
          <ReactLoading type={"bars"} color={"grey"} height={50} width={100} />
        </center>
      ) : (
        <>
          <Container>
            <Row xs={3} md={4}>
              {ListOfNfts.map((item, index) => (
                <Col key={index}>
                  <div className="hoverable">
                    <ItemDisplay tokenId={item} key={index} />
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
          <br />

          <center>
            {ToShowLoadButton ? (
              <Button
                ref={loadbutt}
                onClick={async (e) => {
                  //I know it is crazy not to use useRef here
                  loadbutt.current.disabled = true;
                  //load more and change the end slice as refrence for future
                  let to_load = 16;

                  await loadDataFromRPCUndUpdateState(
                    LastSlice.current,
                    LastSlice.current + to_load
                  );

                  loadbutt.current.disabled = false;
                  LastSlice.current += to_load;
                }}
              >
                Load More
              </Button>
            ) : null}
          </center>
          <br />
          <br />
        </>
      )}
    </div>
  );
}

export default All;
