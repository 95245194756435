//change contract address and chain






import SVGBuilder from "../components/svgbuilder";
import createImageDataFromState from "../services/createImageDataFromState.js";
import { useState, useEffect } from "react";
import axios from "axios";
import { ethers } from "ethers";
import {
  NFTContractAbi,
  pinataApiKey,
  pinataSecretApiKey,
} from "../services/backenddata";
import checkMetaMask from "../services/checkmetamask";

import { confirmAlert } from "react-confirm-alert";
var svgToImage = require("svg-to-image");
var getContext = require("get-canvas-context");

function Setter() {
  // it gets set to ListOfCircles when MintMe is clicked
  const [svgdata, setsvgdata] = useState([]);
  async function handleConfirm(myCost, imageSource) {
    let c = window.prompt("token ID");
    const to_uplaod = {
      description:
        "number of circles corresponds to the rarity of this NFT and determine the amount of yields it generates",
      external_url: "www.circles.pictures",
      name: `Circles ${c}`,
      attributes: [
        {
          trait_type: "Number of Circles",
          value: svgdata.length,
        },
      ],
      image: imageSource,
    };

    console.log(to_uplaod);

    if (window.ethereum) {
      try {
        //return list of accounts
        await window.ethereum.request({
          // method: "eth_requestAccounts",
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: '0xA86A',
              chainName: 'Avalanche Mainnet C-Chain',
              nativeCurrency: {
                  name: 'Avalanche',
                  symbol: 'AVAX',
                  decimals: 18
              },
              rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
              blockExplorerUrls: ['https://snowtrace.io/']
          },
          ],
        });
        await window.ethereum.request({
          method: "eth_requestAccounts",
        });
      } catch (error) {
        if (error.code === 4001) {
          // User rejected request
          confirmAlert({
            title: "Request rejected",
            message: "Please accept to connect to the dApp",
            buttons: [
              {
                label: "OK!",
                onClick: () => {},
              },
            ],
          });
        } else {
          confirmAlert({
            title: "Request rejected",
            message: "Please accept to connect to the dApp",
            buttons: [
              {
                label: "OK!",
                onClick: () => {},
              },
            ],
          });
        }
      }

      confirmAlert({
        title: "Wait a sec !",
        message: "You can already confirm the purchase",
        buttons: [],
      });

      axios
        .post(`https://api.pinata.cloud/pinning/pinJSONToIPFS`, to_uplaod, {
          headers: {
            pinata_api_key: pinataApiKey,
            pinata_secret_api_key: pinataSecretApiKey,
          },
        })
        .then(async function (response) {
          //if request went well
          let ipfshash = response.data.IpfsHash;
          try {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const Contract = new ethers.Contract(
              "0xBA48fC180C496741cbbe8f2e5d0b38c9f66426bD",
              NFTContractAbi,
              provider
            );

            const signedContract = Contract.connect(signer);
            const { hash } = await signedContract.masterSetTokenId(
              parseInt(prompt("which NFT")),
              `ipfs://${ipfshash}`,
              parseInt(svgdata.length.toString())
            );
            confirmAlert({
              message: "Transaction pending... 💲",
              buttons: [],
            });
            await provider.waitForTransaction(hash); // Wait till the transaction is mined
            confirmAlert({
              title: (
                <div
                  style={{ backgroundColor: "#F9F2F2" }}
                  id="myElement"
                ></div>
              ),
              message: `The NFT is yours !!!!.`,
              buttons: [
                {
                  label: "go back.",
                  onClick: () => {
                    window.location.reload();
                  },
                },
                {
                  label: "go to my collection.",
                  onClick: async () => {
                    const add = await signer.getAddress();
                    window.open(`/address/${add}`, "_parent");
                  },
                },
              ],
            });
          } catch (e) {
            console.log(e);
            //blockchain error
            if (e.code === -32603) {
              confirmAlert({
                title: "Failed !😩",
                message: "insufficient funds in your wallet 🥺 💸",
                buttons: [
                  {
                    label: "Go back.!",
                    onClick: () => {},
                  },
                ],
              });
            } else {
              confirmAlert({
                title: "Failed ! 😩",
                message: e.data.message.toString().substring(0, 50),
                buttons: [
                  {
                    label: "Go back.!",
                    onClick: () => {},
                  },
                ],
              });
            }
          }
        })
        .catch(function (error) {
          //pinata has a problem
          console.log(error);
        });
    } else {
      checkMetaMask();
    }
  }

  // HANDLE MINT IF User does not changing anything
  async function handleMint() {
    //CREATING IMAGE SOURCE"
    // createImageDataFromState : take the state and generater <svg> component
    svgToImage(createImageDataFromState(svgdata), function (err, image) {
      if (err) {
        console.log(err);
      }
      // set up a new Canvas2D
      var context = getContext("2d", {
        width: 500,
        height: 500,
      });
      // draw image to canvas
      context.drawImage(image, 0, 0);
      // const imageSource = JSON.stringify(context.canvas.toDataURL("image/png"));
      const imageSource = context.canvas.toDataURL("image/png");

      //calculate cost
      // flooring is for numbers divisible by 3
      let myCost = Math.round(svgdata.length) - 2;
      myCost = Math.floor(myCost);
      confirmAlert({
        // title:
        title: <img src={imageSource} width="350" alt="greatest NFT" />,
        message: `The NFT costs ${myCost} AVAX 💸`,
        buttons: [
          {
            label: "Let's go 🏄‍♀️",
            onClick: () => handleConfirm(myCost, imageSource),
          },
          {
            label: "back to mommy",
            onClick: () => {},
          },
        ],
      });
    });
  }

  useEffect(() => {
    // so it does not start on relaoding the page
    if (svgdata.length !== 0) {
      // handleMint();
    }

    return () => {};
  }, [svgdata]);

  return (
    <div style={{ marginTop: "90px" }}>
      <div style={{ marginTop: "30px" }}>
        <SVGBuilder mint={handleMint} setsvgdata={setsvgdata} />
      </div>
    </div>
  );
}

export default Setter;
