import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import { ethers } from "ethers";
import { NFTContractAbi, MyNFTContract } from "../services/backenddata";
import { useRef } from "react";
import { AVALANCHE_TESTNET_PARAMS } from "../services/backenddata";
import checkMetaMask from "../services/checkmetamask";
export default function RaffleMint() {
  const btn = useRef(null);
  const handlClick = async () => {
    if (window.ethereum) {
      try {
        //return list of accounts
        await window.ethereum.request({
          // method: "eth_requestAccounts",
          method: "wallet_addEthereumChain",
          params: [AVALANCHE_TESTNET_PARAMS],
        });
        await window.ethereum.request({
          method: "eth_requestAccounts",
        });
      } catch (error) {
        if (error.code === 4001) {
          // User rejected request
          confirmAlert({
            title: "Request rejected",
            message: "Please accept to connect to the dApp",
            buttons: [
              {
                label: "OK!",
                onClick: () => {},
              },
            ],
          });
        } else {
          confirmAlert({
            title: "Request rejected",
            message: "Please accept to connect to the dApp",
            buttons: [
              {
                label: "OK!",
                onClick: () => {},
              },
            ],
          });
        }
      }

      confirmAlert({
        message: "Please sign the transaction with metamask ",
        buttons: [],
      });

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const Contract = new ethers.Contract(
        MyNFTContract,
        NFTContractAbi,
        provider
      );
      const signedContract = Contract.connect(signer);

      try {
        const { hash } = await signedContract.mintItem({
          value: ethers.utils.parseEther("1"),
        });


        const to_send = { address: await signer.getAddress() ,txhash: hash};


        confirmAlert({
          closeOnEscape: false,
          closeOnClickOutside: false,
          title: "Confirming...",
          message: "The NFT will be in your wallet in a few seconds.",
          buttons: [],
        });

        const res = await axios.post(
          "https://api.circles.pictures/mint",
          to_send
        );

        console.log(res);
        confirmAlert({
          title: "Congratulations...",
          message: "The NFT is yours 🎉",

          buttons: [
            {
              label: "go back.",
              onClick: () => {},
            },
          ],
        });
      } catch (error) {
        //no funds

        confirmAlert({
          title: "Something went wrong...",
          message: "Your wallet does not have enough funds 😭 Or you rejected the metamask request 🔴",

          buttons: [
            {
              label: "go back.",
              onClick: () => {},
            },
          ],
        });
      }
    } else {
      checkMetaMask();
    }
  };

  return (
    <div className="mintdiv">

      <button
        ref={btn}
        onClick={() => handlClick()}
        className="btn btn-primary"
        style={{
          margin: "0 50%",
          width: 360,
          transform: "translate(-50%, 0)",
          fontSize: 48,
        }}
      >
        RAFFLE MINT!
        <p style={{ fontSize: 18 }}> 1 avax</p>
      </button>
    </div>
  );
}
