import "./styles.css";
import { MyNFTContract, NFTContractAbi } from "../services/backenddata";
import { ethers } from "ethers";
import { Button, Form, Card, Container, Row, Col } from "react-bootstrap";
import { useState } from "react";
export default function SendDialog({
  setShowAddressInput,
  confirmAlert,
  URLaddress,
  itemId,
}) {
  const [address, setaddress] = useState();

  async function handleSend(id, a) {
    if (a.length < 10) {
      confirmAlert({
        title: "Failed ! 😞",
        message: "check the address",
        buttons: [
          {
            label: "Go back.!",
            onClick: () => {},
          },
        ],
      });
    } else {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const Contract = new ethers.Contract(
          MyNFTContract,
          NFTContractAbi,
          provider
        );
        let signer = provider.getSigner();

        const signedContract = Contract.connect(signer);

        const { hash } = await signedContract[
          "safeTransferFrom(address,address,uint256)"
        ](URLaddress, a, id);
        console.log(hash);

        confirmAlert({
          closeOnEscape: false,
          closeOnClickOutside: false,
          message: "Transaction pending...",

          buttons: [],
        });
        await provider.waitForTransaction(hash); // Wait till the transaction is mined
        confirmAlert({
          message: "done !",

          buttons: [
            {
              label: "Go back.!",
              onClick: () => {
                window.location.reload();
              },
            },
          ],
        });
      } catch (error) {
        confirmAlert({
          title: "Failed ! 😩",
          buttons: [
            {
              label: "Go back.!",
              onClick: () => {},
            },
          ],
        });
      }
    }
  }

  return (
    <>
      <div id="overlay">
        <Card
          style={{
            position: "absolulte",
            top: "13%",

            margin: " 0 auto",
            width: "30rem",
          }}
        >
          <Card.Body>
            <Card.Title>sending (Circles {itemId})</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              make sure the address is correct. You can not refund the NFT if it
              was sent to the wrong address.
            </Card.Subtitle>
            <br />
            <Form.Label htmlFor="address">
              The Address you want to send the NFT to ✋🏽
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="0x76194e3e3c2..."
              id="address"
              value={address}
              onChange={(e) => setaddress(e.target.value)}
            />
            <br />
            <Card.Text>
              <Container>
                <Row>
                  <Col>
                    <Button
                      onClick={(e) => {
                        handleSend(itemId, address);
                      }}
                    >
                      Confirm
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      variant="secondary"
                      onClick={(e) => {
                        setShowAddressInput(false);
                      }}
                    >
                      Go Back
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
