function EmptyAddressComponent() {
  return (
    <div className="emailcomp">
      <h3>This address does not hold any Circles NFT.</h3>
      <p>
        It is possible that the NFTs of this address are being staked or auctioned at the moment.<br/> This means they are being held by an approved smart contract.        
      </p>

      <button style={{ backgroundColor: "black" }} onClick= {()=>window.open("/", "_self")}>Go to the homepage</button>

    </div>
  );
}

export default EmptyAddressComponent;
