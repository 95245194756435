import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
// import Mailchimp from "react-mailchimp-form";

// import { ethers } from "ethers";
// import {
//   MyNFTContract,
//   NFTContractAbi,
//   public_rpc_url,
// } from "./services/backenddata";
import kalao from "./assets/kalao.png";
import { Row, Col, Card, Container, Button } from "react-bootstrap";
// import { Link } from "react-router-dom";
import ReactLoading from "react-loading";
// import SmallNFTDisplay from "./components/small_nft_display";
// import background from "./assets/download.png";
import blue_back from "./assets/blue_with_slop.png";
import ex from "./assets/ex.gif";
import tw from "./assets/twitter.png";
import gala from "./assets/galactore.png";
import dis from "./assets/discord.png";
import medium from "./assets/medium.png";
import gitbook from "./assets/gitbook.jpeg";
import campfilre from "./assets/campfire.png";
import tofu from "./assets/tofu.png";
import ItemDisplayNoDetails from "./components/item_display_no_details";
import getRandomIntInclusive from "./services/randomnumber";
import MintCointer from "./components/MintCounter";
function App() {
  const [isLoading, setisLoading] = useState(false);
  // const [Counter, setCounter] = useState(1);
  // const [Owner, setOwner] = useState("0x00000000000000000000000000");
  const firstScroll = useRef(null);
  const secondScroll = useRef(null);

  useEffect(() => {
    setisLoading(false);

    // async function mainy() {
    //   let url = public_rpc_url;
    //   let provider = new ethers.providers.JsonRpcProvider(url);
    //   const Contract = new ethers.Contract(
    //     MyNFTContract,
    //     NFTContractAbi,
    //     provider
    //   );
    //   let counter = await Contract._tokenCounter();
    //   //formatting
    //   counter = ethers.utils.formatEther(counter) * 10 ** 18 - 1;
    //   setCounter(counter);
    //   // ethers.utils.parseEther(counter.toString())
    //   const owner = await Contract.ownerOf(counter);
    //   setOwner(owner);

    //   setisLoading(false);
    // }

    // mainy();

    return () => {};
  }, []);

  return (
    <div style={{ marginTop: "60px" }}>
      {isLoading ? (
        <center>
          <ReactLoading type={"bars"} color={"grey"} height={50} width={100} />
        </center>
      ) : (
        <div>
          <div>
            <br />

            <Container>
              <Row className="align-items-center">
                <Col xs={12} md={6}>
                  <Row>
                    <h3
                      style={{
                        textShadow: "1px 1px 10px white",
                      }}
                    >
                      {/* Last mint: {Counter} / 3142 */}
                      Generative NFT 🔺
                    </h3>
                  </Row>
                  <Row>
                    <br />
                    <br />
                    <h6 style={{ color: "grey", fontSize: "1.3em" }}>
                      Yields-generating NFTs with on-chain governance built on
                      Avalanche.
                    </h6>
                    {/* Costomizing mint: You can design your own NFT but the cost
                      of minting corresonds to the rarity and yields the NFT has{" "}
                      <b>or</b> you raffle mint and try your luck to get a token
                      with random rarity. */}
                    <br />
                    <br />
                    <br />
                  </Row>
                  <Row>
                    <br />
{/* 
                    <div className="emailcompi">
                    <br/>
                      <br/>

                    Join our mailing list ✉️

                    <Mailchimp
                      action="https://pictures.us14.list-manage.com/subscribe/post?u=e5e79b2b9ecb5e30912493bcb&amp;id=9cb9d33ccd"
                      fields={[
                        {
                          name: "EMAIL",
                          placeholder: "Email",
                          type: "email",
                          required: true,
                        },
                      ]}
                    />
                    </div> */}
                  </Row>
                  <br />
                </Col>

                <Col>
                  <Card
                    style={{
                      margin: "0 auto",
                      width: "18rem",
                      color: "inherit",
                      textDecoration: "inherit",
                    }}
                  >
                    <Link to="/items">
                      <div className="hoverable">
                        <ItemDisplayNoDetails
                          tokenId={getRandomIntInclusive(1, 20)}
                        />
                      </div>
                    </Link>

                    <Card.Body>
                      <Card.Text>
                        <MintCointer />
                        {/* <Link
                          to={`/address/${Owner}`}
                          style={{ textDecoration: "inherit" }}
                        >
                          owned by <strong>{Owner.substring(0, 15)}...</strong>
                        </Link> */}
                        {/* We will launch on <b>27th April</b> */}
                      </Card.Text>
                      <Card.Text
                        as={Link}
                        to="/mint"
                        style={{ textDecoration: "none" }}
                      >
                        Go to the mint 🚀
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
          {/* --------------------- */}
          <div ref={firstScroll}>
            <br />
            <Container style={{ height: 120 }}></Container>
          </div>
          {/* --------------------- */}
          <div
            style={{
              height: "80vh",
              backgroundImage: `url(${blue_back})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% auto",
            }}
          >
            <Container fluid style={{ paddingTop: "10vw" }}>
              <Row className="justify-content-md-center">
                <Col className="justify-content-md-center">
                  <Card
                    style={{
                      margin: "0 auto",
                      width: "25vw",
                      color: "inherit",
                      textDecoration: "inherit",
                    }}
                  >
                    <Card.Img src={ex} alt="example" />
                  </Card>
                </Col>
                <Col style={{ color: "white" }} xs={8}>
                  <h3>What is Circles NFT?</h3>
                  <p style={{ fontSize: "1.8vw", width: "60vw" }}>
                    The first NFT project on Avalanche where users create their
                    custom NFT. The cost of minting NFT corresponds with the
                    circles the users put in it. There are 3,142 NFTs with
                    unlimited possiblites.The numbers of circles in NFTs
                    determine the yields and the rarity of it. There are 2
                    possibilities to mint a Circle NFT, you raffle mint (get a
                    random number of circles between 1-20) or custom mint (get
                    at least 3 circles with the cost of at least 1 AVAX with
                    more customizable NFTs) Hoding the Circles NFT enables you
                    to get the governance deflationary token PieCoin.
                    <br />
                    <Button
                      style={{ fontSize: "2vw", marginTop: "1vw" }}
                      variant="light"
                      onClick={() => {
                        secondScroll.current.scrollIntoView();
                      }}
                    >
                      Join the Community
                    </Button>
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
          {/* --------------------- */}
          <div ref={secondScroll}>
            <br />
          </div>

          {/* --------------------- */}
          <div
            style={{
              height: "400",
            }}
          >
            <Container style={{ paddingTop: "10vw" }}>
              <Row>
                <Col xs={8}>
                  <h1>Join our community</h1>
                  <br />
                  <a
                    href="https://twitter.com/circles_nft"
                    target="_blank"
                    rel="noreferrer"
                    style={{ paddingRight: 20 }}
                  >
                    <img src={tw} width="50" alt="twt" />
                  </a>
                  {"     "}
                  <a
                    href="https://discord.gg/cbT7Uq6Dgq"
                    target="_blank"
                    rel="noreferrer"
                    style={{ paddingRight: 20 }}
                  >
                    <img src={dis} width="60" alt="dis" />
                  </a>

                  <a
                    href="https://circles-nft.gitbook.io/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ paddingRight: 20 }}
                  >
                    <img src={gitbook} width="60" alt="git" />
                  </a>
                  <a
                    href="https://medium.com/@circles_nft"
                    target="_blank"
                    rel="noreferrer"
                    style={{ paddingRight: 20 }}
                  >
                    <img src={medium} width="60" alt="med" />
                  </a>
                </Col>
              </Row>
            </Container>
          </div>

          {/* --------------------- */}

          <div
            style={{
              height: "400",
            }}
          >
            <Container style={{ paddingTop: "10vw" }}>
              <Row>
                {" "}
                <Col xs={8}>
                  <h1>Special Thanks</h1>
                  <br />
                </Col>
              </Row>
              <center>
                <Row className="justify-content-md-center">
                  <Col xs={8} md={5}>
                    <a
                      href="https://www.avaxradar.com/2022/03/democratization-of-nft-space.html"
                      target="_blank"
                      rel="noreferrer"
                      style={{ paddingRight: 20 }}
                    >
                      <Card style={{ maxWidth: 300, height: 120 }}>
                        <div className="hoverable">
                          <img
                            style={{ maxWidth: 290 }}
                            alt="radar"
                            src="https://1.bp.blogspot.com/-OEDQkB2hdKM/YdGZ5do-5AI/AAAAAAAAAAU/AH-Z2nM8UMgA5Pk0jO-YW2e9jvLHXWBPwCNcBGAsYHQ/s487/AvaxRadar-01.png"
                          />
                        </div>
                      </Card>
                    </a>
                  </Col>
                  <Col xs={8} md={5}>
                    <a
                      href="https://marketplace.kalao.io/collection/0xba48fc180c496741cbbe8f2e5d0b38c9f66426bd"
                      target="_blank"
                      rel="noreferrer"
                      style={{ paddingRight: 20 }}
                    >
                      <Card style={{ maxWidth: 300 }}>
                        <div className="hoverable">
                          <img
                            style={{ maxWidth: 290, height: 120 }}
                            alt="kalao"
                            src={kalao}
                          />
                        </div>
                      </Card>
                    </a>
                  </Col>
                </Row>
                <Row className="justify-content-md-center">
                  <Col xs={8} md={5}>
                    <a
                      href="https://tofunft.com/collection/circles-nft/"
                      target="_blank"
                      rel="noreferrer"
                      style={{ paddingRight: 20 }}
                    >
                      <Card style={{ maxWidth: 300 }}>
                        <div className="hoverable">
                          <img
                            style={{ maxWidth: 290, height: 120 }}
                            alt="tofu"
                            src={tofu}
                          />
                        </div>
                      </Card>
                    </a>
                  </Col>

                  <Col xs={8} md={5}>
                    <a
                      href="https://www.campfire.exchange/collections/0xba48fc180c496741cbbe8f2e5d0b38c9f66426bd"
                      target="_blank"
                      rel="noreferrer"
                      style={{ paddingRight: 20 }}
                    >
                      <Card style={{ maxWidth: 300 }}>
                        <div className="hoverable">
                          <img
                            style={{ maxWidth: 290, height: 120 }}
                            alt="campfire"
                            src={campfilre}
                          />
                        </div>
                      </Card>
                    </a>
                  </Col>

                  <Col xs={8} md={5}>
                    <a
                      href="https://twitter.com/circles_nft/status/1514569816295751680/"
                      target="_blank"
                      rel="noreferrer"
                      style={{ paddingRight: 20 }}
                    >
                      <Card style={{ maxWidth: 300 }}>
                        <div className="hoverable">
                          <img
                            style={{ maxWidth: 290, height: 120 }}
                            alt="gala"
                            src={gala}
                          />
                        </div>
                      </Card>
                    </a>
                  </Col>
                </Row>
              </center>
            </Container>
          </div>

          <br />
          <br />
          <br />

          <br />
          <br />
          <br />
        </div>
      )}
    </div>
  );
}

export default App;
