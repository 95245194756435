import { Container, Row, Col, Button } from "react-bootstrap";
import "./svgcomponenets/svgcss.css";
import { useState } from "react";
import DisplayComponent from "./svgcomponenets/displaycomponent";
import EditiorForm from "./svgcomponenets/editorformcomponent";
import { confirmAlert } from "react-confirm-alert";
import getRandomColor from "../services/randomcolor";
import getRandomIntInclusive from "../services/randomnumber";
import shuffle from "../services/shuffler";
class Circle {
  constructor(cx, cy, r, stroke, strokeWidth, fill, opacity) {
    this.cx = cx;
    this.cy = cy;
    this.r = r;
    this.stroke = stroke;
    this.strokeWidth = strokeWidth;
    this.fill = fill;
    this.opacity = opacity;
  }
}

// import Circle from "./circle_model";

function SVGBuilder({ setsvgdata, mint }) {
  const initalCircle = new Circle(
    getRandomIntInclusive(100, 300).toString(),
    getRandomIntInclusive(100, 300).toString(),
    getRandomIntInclusive(50, 150).toString(),
    getRandomColor(),
    getRandomIntInclusive(1, 4).toString(),
    getRandomColor(),
    (getRandomIntInclusive(80, 100) / 100).toString()
  );
  const initalCircle1 = new Circle(
    getRandomIntInclusive(250, 400).toString(),
    getRandomIntInclusive(250, 400).toString(),
    getRandomIntInclusive(50, 150).toString(),
    getRandomColor(),
    getRandomIntInclusive(1, 4).toString(),
    getRandomColor(),
    (getRandomIntInclusive(65, 100) / 100).toString()
  );
  const initalCircle2 = new Circle(
    "480",
    "480",
    getRandomIntInclusive(60, 200).toString(),
    getRandomColor(),
    getRandomIntInclusive(1, 4).toString(),
    getRandomColor(),
    (getRandomIntInclusive(80, 100) / 100).toString()
  );

  const [Wid, setWid] = useState(500);
  const [Hei, setHei] = useState(500);
  const [EditedCircleIndex, setEditedCircleIndex] = useState(2);
  const [ListOfCircles, setListOfCircles] = useState([
    initalCircle,
    initalCircle1,
    initalCircle2,
  ]);

  return (
    <Container>
      <Row>
        <Col>
          <DisplayComponent ListOfCircles={ListOfCircles} Hei={Hei} Wid={Wid} />
        </Col>
        <Col>

          <Button
            style={{ width: "100%" }}
            onClick={async (e) => {
              confirmAlert({
                title: `Loading...`,
                buttons: [],
              });

              setsvgdata(ListOfCircles);

              mint();
            }}
          >
            MINT ME!
          </Button>
          <span style={{ fontSize: 14, color: "grey", fontWeight: "bold" }}>
            {ListOfCircles.length - 2} AVAX
          </span>
          <EditiorForm
            setEditedCircleIndex={setEditedCircleIndex}
            EditedCircleIndex={EditedCircleIndex}
            Circle={Circle}
            setListOfCircles={setListOfCircles}
            ListOfCircles={ListOfCircles}
            setHei={setHei}
            Hei={Hei}
            Wid={Wid}
            setWid={setWid}
          />
        </Col>
      </Row>
      <Row>

        <Col>
        <Button variant="light" onClick={() => setListOfCircles(shuffle([...ListOfCircles]))}>
            🔀
          </Button>
          <br />
          <br /> <br />
          {/* <Button
            onClick={(e) => {
              const data = JSON.parse(textArea.current.value);
              setListOfCircles(data);
            }}
          >
            Import SVG MetaData
          </Button>
          <br />
          <br />
          <textarea ref={textArea} rows={5} cols={80} placeholder="..." />
          <code></code> */}
        </Col>
      </Row>
    </Container>
  );
}

export default SVGBuilder;
