import { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
const futureDate = new Date(1651082400 * 1000);
const getDateDiff = (date1, date2) => {
  const diff = new Date(date2.getTime() - date1.getTime());
  return {
    year: diff.getUTCFullYear() - 1970,
    month: diff.getUTCMonth(),
    day: diff.getUTCDate() - 1,
    hour: diff.getUTCHours(),
    minute: diff.getUTCMinutes(),
    second: diff.getUTCSeconds(),
  };
};

function EmailComponent() {
  const [isLoading, setisLoading] = useState(true);
  const [diff, setDiff] = useState({});

  useEffect(() => {
    const timer = setInterval(() => {
      setDiff(getDateDiff(new Date(), futureDate));
      setisLoading(false);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="emailcomp">
      <h3>Time to launch.</h3>

      {isLoading ? (
        <>
          <div className="timer">
            <div>
              <Spinner animation="grow" />
              <span>d</span>
            </div>
            <div>
              <Spinner animation="grow" /> <span>h</span>
            </div>
            <div>
              <Spinner animation="grow" /> <span>m</span>
            </div>
            <div>
              <Spinner animation="grow" /> <span>s</span>
            </div>
          </div>
        </>
      ) : (
        <div className="timer">
          <div>
            {diff.day}
            <span>d</span>
          </div>
          <div>
            {diff.hour}
            <span>h</span>
          </div>
          <div>
            {diff.minute}
            <span>m</span>
          </div>
          <div>
            {diff.second}
            <span>s</span>
          </div>
        </div>
      )}

      {diff.day === 30 ? <h3>minting is open !! relaod the page :)</h3> : null}
    </div>
  );
}

export default EmailComponent;
