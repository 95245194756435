import { useEffect, useState } from "react";
import { ethers } from "ethers";
import {
  MyNFTContract,
  NFTContractAbi,
  public_rpc_url,
} from "../services/backenddata";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactLoading from "react-loading";
import { useParams } from "react-router-dom";
import ItemDisplay from "../components/item_display";

function ShowNFT() {
  let { id } = useParams();

  const [isLoading, setisLoading] = useState(true);
  const [LastNFT, setLastNFT] = useState(null);
  const [Owner, setOwner] = useState("0x00000000000000000000000000");

  useEffect(() => {
    async function mainy() {
      let url = public_rpc_url;
      let provider = new ethers.providers.JsonRpcProvider(url);
      const Contract = new ethers.Contract(
        MyNFTContract,
        NFTContractAbi,
        provider
      );
      const last_nft_uri = await Contract.tokenURI(id);
      const owner = await Contract.ownerOf(id);
      setOwner(owner);

      // "ipfs://<hash>"
      let ipfs_url = `https://ipfs.nftrade.com/ipfs/${last_nft_uri.substring(
        7
      )}`;

      await fetch(ipfs_url)
        .then((res) => res.json())
        .then((data) => setLastNFT(data))
        .catch((e) => console.log(e));

      setisLoading(false);
    }
    mainy();

    return () => {};
  }, [id]);

  return (
    <div style={{ marginTop: "60px" }}>
      {isLoading ? (
        <center>
          <ReactLoading type={"bars"} color={"grey"} height={50} width={100} />
        </center>
      ) : (
        <>
          <br />
          <Container>
            <Row className="align-items-center">
              <Col>
                <Row>
                  <h2>
                    Circles NFT number {LastNFT.name.substring(7).trim()}{" "}
                  </h2>

                </Row>
                <Row>
                  <h6>
                  number of circles corresponds to the rarity of this NFT und determine the amount of yields it generates

                  </h6>
                  <Link
                    to={`/address/${Owner}`}
                    style={{ textDecoration: "inherit" }}
                  >
                    owned by <strong>{Owner.substring(0, 25)}...</strong>
                  </Link>
                </Row>
                <Row style={{height: 100}}></Row>
                <Row>
                  <Link to="/items" style={{textDecoration: "none", fontSize: 24}}>See all NFTs</Link>
                </Row>
              </Col>

              <Col md={5} sm = {12}>
              <ItemDisplay tokenId={id}/>

<br />
              </Col>

            </Row>
          </Container>
        </>
      )}
    </div>
  );
}

export default ShowNFT;
