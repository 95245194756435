import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import checkMetaMask from "../services/checkmetamask";
import NFTDisplay from "../components/nft_display";
import { Container, Row, Col } from "react-bootstrap";
import { ethers } from "ethers";
import ReactLoading from "react-loading";
import {
  public_rpc_url,
  AuctionABI,
} from "../services/backenddata";
import DetailedAuctionCard from "../components/DetaildAuctionCard";


class DetailedAuction {
  constructor(
    directBuyPrice,
    isDirectBuy,
    maxBid,
    isCancelled,
    maxBidder,
    owner,
    tokenId,
    endTime,
    startTime,
    startPrice,
    auctionState,
    bidderAddresses,
    bidderAmounts,
    nftAddress,
    isAdmin,
    minIncrement
  ) {
    this.directBuy = directBuyPrice;
    this.isDirectBuy = isDirectBuy;
    this.maxBidder = maxBidder;
    this.maxBid = maxBid;
    this.isCancelled = isCancelled;
    this.owner = owner;
    this.tokenId = tokenId;
    this.endTime = endTime;
    this.startTime = startTime;
    this.startPrice = startPrice;
    this.auctionState = auctionState;
    this.bidderAddresses = bidderAddresses;
    this.bidderAmounts = bidderAmounts;
    this.nftAddress = nftAddress;
    this.isAdmin = isAdmin;
    this.minIncrement =minIncrement;
  }
}

export default function ShowAuction() {
  let { contractAddress } = useParams();
  const [auctionDetails, setauctionDetails] = useState(null);
const [userAddress, setuserAddress] = useState(null);


  useEffect( () => {
    async function LoadDataFromRPCAndUpdateState() {
      let url = public_rpc_url;
      let provider = new ethers.providers.JsonRpcProvider(url);
      const AuctionContract = new ethers.Contract(
        contractAddress,
        AuctionABI,
        provider
      );
  
      // let directBuy = await AuctionContract.directBuy();
      // console.log(creator);
  
      //wrap all Bids into List into Bid[]
      let allBids = await AuctionContract.allBids();
  
      let detailedAuction = new DetailedAuction(
        ethers.utils.formatEther(await AuctionContract.directBuyPrice()),
        await AuctionContract.isDirectBuy(),
        ethers.utils.formatEther(await AuctionContract.maxBid()),
        await AuctionContract.isCancelled(),
        await AuctionContract.maxBidder(),
        await AuctionContract.creator(),
        ethers.utils.formatEther(await AuctionContract.tokenId()) * 10 ** 18,
        ethers.utils.formatEther(await AuctionContract.endTime()) * 10 ** 18,
        ethers.utils.formatEther(await AuctionContract.startTime()) * 10 ** 18,
        ethers.utils.formatEther(await AuctionContract.startPrice()),
        await AuctionContract.getAuctionState(),
        allBids[0],
        //refomate the amounts
        allBids[1].map(amount=>ethers.utils.formatEther(amount)),
        await AuctionContract.nftAddress(),
        false,
        // ethers.utils.formatEther(await AuctionContract.minIncrement()),
        0.01
      );
      console.log(allBids);
  
      setauctionDetails(detailedAuction);
    }
  
    async function loadDataFromMetaUndUpdateState() {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const AuctionContract = new ethers.Contract(
        contractAddress,
        AuctionABI,
        provider
      );
      const signer = provider.getSigner();
  
      const signerAddress = await signer.getAddress();
      setuserAddress(signerAddress);
  
      let allBids = await AuctionContract.allBids();
      console.log(allBids);
      let creator = await AuctionContract.creator();
      let detailedAuction = new DetailedAuction(
        ethers.utils.formatEther(await AuctionContract.directBuyPrice()),
        await AuctionContract.isDirectBuy(),
        ethers.utils.formatEther(await AuctionContract.maxBid()),
        await AuctionContract.isCancelled(),
        await AuctionContract.maxBidder(),
        creator,
        ethers.utils.formatEther(await AuctionContract.tokenId()) * 10 ** 18,
        ethers.utils.formatEther(await AuctionContract.endTime()) * 10 ** 18,
        ethers.utils.formatEther(await AuctionContract.startTime()) * 10 ** 18,
        ethers.utils.formatEther(await AuctionContract.startPrice()),
        await AuctionContract.getAuctionState(),
        allBids[0],
        allBids[1].map(amount=>ethers.utils.formatEther(amount)),
        await AuctionContract.nftAddress(),
        creator === signerAddress,
        0.01
        // ethers.utils.formatEther(await AuctionContract.minIncrement()),
      );
  
      setauctionDetails(detailedAuction);
    }

    async function mainy() {

      if (window.ethereum) {
        checkMetaMask();
        await loadDataFromMetaUndUpdateState();
      } else {
        await LoadDataFromRPCAndUpdateState();
      }
      
    }
    mainy();

    return () => {};
  }, [contractAddress]);

  return (
    <div style={{ marginTop: "60px" }}>
      <Container>
        
          {auctionDetails === null ? (
        <center>
        <ReactLoading type={"bars"} color={"grey"} height={50} width={100} />
      </center>
          ) : (
            <>
            <Row xs={2} md={2}>
              <Col className="mt-1">
                <DetailedAuctionCard
                  auction={auctionDetails}
                  auctionAddress={contractAddress}
                  userAddress = {userAddress}
                />
              </Col>
              <Col>
                <NFTDisplay
                  tokenId={auctionDetails.tokenId}
                />
              </Col>
              </Row>
            </>
          )}
        
      </Container>
    </div >
  );
}

