import { ethers } from "ethers";
import { Spinner } from "react-bootstrap";
import { useEffect, useState } from "react";
import { MyNFTContract, NFTContractAbi } from "../services/backenddata";
import { public_rpc_url } from "../services/backenddata";
export default function MintCointer() {
  const [isLoading, setisLoading] = useState(true);
  const [counter, setCounter] = useState(true);
  useEffect(() => {
    async function mainy() {
      let url = public_rpc_url;
      let provider = new ethers.providers.JsonRpcProvider(url);

      const Contract = new ethers.Contract(
        MyNFTContract,
        NFTContractAbi,
        provider
      );

      let c = await Contract._tokenCounter();

      //formatting
      c = ethers.utils.formatEther(c) * 10 ** 18 - 1;

      setCounter(Math.floor(c));
      // ethers.utils.parseEther(counter.toString())

      setisLoading(false);
    }

    mainy();

    return () => {};
  }, []);

  return (
    <>
      {" "}
      <h4>


        {isLoading ? (
          <Spinner animation="grow" size="sm" />
        ) : (
          `${counter}/3142 minted`
        )}
      </h4>
    </>
  );
}
