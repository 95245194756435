function ChangeFillColorComponent({
  name,
  ListOfCircles,
  setListOfCircles,
  EditedCircleIndex,
}) {
  return (
    <>
      <label htmlFor="Circle Radius" className="grey-text">
        {name} : {ListOfCircles[EditedCircleIndex].fill}
      </label>
      <br />
      <select
        value={ListOfCircles[EditedCircleIndex].fill}
        onChange={(e) =>
          setListOfCircles((prev) =>
            prev.map((circle, index) => {
              if (index === EditedCircleIndex) {
                const newCircle = circle;
                newCircle.fill = e.target.value;
                return newCircle;
              }
              return circle;
            })
          )
        }
      >
        <option value="none"> - </option>
        <option value="#0C090A">Night</option>
        <option value="#98AFC7">Blue Gray</option>
        <option value="#000080">Navy</option>
        <option value="#0041C2	">Blueberry Blue</option>
        <option value="#E6E6FA">Lavender</option>
        <option value="#25383C">DarkSlateGray</option>
        <option value="#50C878">Emerald</option>
        <option value="#808000">Olive</option>
        <option value="#006400">Dark Green</option>

        <option value="#00FF00">Lime</option>
        <option value="#F5F5DC">Beige</option>
        <option value="#F0E68C">Khaki</option>
        <option value="#FFFF00">Yellow</option>
        <option value="#FBB117">Beer</option>
        
        <option value="#7FFD">Aquamarine</option>

        <option value="#FFD700">Gold</option>
        <option value="#3B2F2F">Dark Coffee</option>
        <option value="#C04000">Mahogany</option>
        <option value="#E78A61">Tangerine</option>
        <option value="#B21807">Tomato Sauce Red</option>
        <option value="#550A35">Purple Lily</option>
        <option value="#810541">Purple Maroon</option>
        <option value="#872657">Dark Raspberry</option>
        <option value="#E8ADAA">Rose</option>
        <option value="#FFC0CB">Pink</option>
        <option value="#DC143C">Crimson</option>
        <option value="#DA70D6">Orchid</option>
        <option value="#915F6D">Mauve Taupe</option>
        <option value="#5E5A80">Grape</option>
        <option value="#6960EC">Blue Lotus</option>
        <option value="#8B008B">DarkMagenta</option>
        <option value="#FFF9E3">Egg Shell</option>
        <option value="#FFA500">Orange</option>


        
        



      </select>
    </>
  );
}

export default ChangeFillColorComponent;
