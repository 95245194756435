function ChangeOpacityComponent({
  name,
  ListOfCircles,
  setListOfCircles,
  EditedCircleIndex,
}) {
  return (
    <>
      <label htmlFor="Circle Radius" className="grey-text">
        {name} : {ListOfCircles[EditedCircleIndex].opacity}
      </label>

      <input
        type="range"
        min={0}
        max={1}
        step={0.01}
        id="Circle Radius"
        className="form-control"
        value={ListOfCircles[EditedCircleIndex].opacity}
        onChange={(e) =>
          setListOfCircles((prev) =>
            prev.map((circle, index) => {
              if (index === EditedCircleIndex) {
                console.log(" h ");
                const newCircle = circle;
                newCircle.opacity = e.target.value;
                return newCircle;
              }
              return circle;
            })
          )
        }
      />
    </>
  );
}

export default ChangeOpacityComponent;
