import { useState, useEffect } from "react";
import { Card , Placeholder} from "react-bootstrap";
import { Link } from "react-router-dom";
import spinner from "../assets/spinner.gif";

import {
  public_rpc_url,
  MyNFTContract,
  NFTContractAbi,
} from "../services/backenddata";
import { ethers } from "ethers";
export default function ItemDisplay({ tokenId }) {
  const [Item, setItem] = useState(null);
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    async function mainy() {
      let url = public_rpc_url;
      let provider = new ethers.providers.JsonRpcProvider(url);

      const NFTContract = new ethers.Contract(
        MyNFTContract,
        NFTContractAbi,
        provider
      );

      const tokenURI = await NFTContract.tokenURI(tokenId);
      	
      fetch(`https://ipfs.nftrade.com/ipfs/${tokenURI.substring(7)}`,null,1000)
        .then((res) => res.json())
        .then((data) => setItem(data))
        .then((bla) => setisLoading(false))
        .catch((e) => console.log(e));
    }

    mainy();

    return () => {};
  }, [tokenId]);

  return (
    <>
      <br />
      {isLoading ? (
        <Card
          className="shadow  bg-white "
          style={{
            cursor:"wait",
            color: "inherit",
            textDecoration: "inherit",
            marginTop: 20,
            borderRadius:0
          }}
        >
          
            <Card.Img
              variant="top"
              src={spinner}
              width="100%"
              style={{ borderBottom: " solid 1px #d3d3d3" }}
            />
            <Card.Body>

            <Placeholder as={Card.Title} animation="glow" >
            <Placeholder xs={6} />
            </Placeholder>
            <Card.Text style={{ fontSize: 10 }}>
              .......
            </Card.Text>
          </Card.Body>
        </Card>
      ) : (
        <Card
          className="shadow  bg-white "
          style={{
            color: "inherit",
            textDecoration: "inherit",
            marginTop: 20,
          }}
          as={Link}
          to={"/id/" + Item.name.substring(7).trim()}
        >
          <Card.Img
            variant="top"
            src={Item.image}
            style={{ borderBottom: " solid 1px #d3d3d3" }}
          />
          <Card.Body>
            <Card.Title>{Item.name}</Card.Title>
            <Card.Text style={{ fontSize: 10 }}>
              {Item.attributes[0].trait_type}:{" "}
              <span style={{ fontWeight: "bold" }}>
                {Item.attributes[0].value}{" "}
              </span>
            </Card.Text>
          </Card.Body>
        </Card>
      )}
    </>
  );
}
