import { useState } from "react";
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import checkMetaMask from "../services/checkmetamask";
import { ethers } from "ethers";
import { confirmAlert } from "react-confirm-alert";
import { AuctionABI } from "../services/backenddata";
import timeConverter from "../services/time_converter";
import state from "../services/auctionstates";
import { Link } from "react-router-dom";
export default function DetailedAuctionCard({
  auction,
  auctionAddress,
  userAddress,
}) {
  const [Bid, setBid] = useState(
    parseFloat(auction.maxBid) === 0
      ? parseFloat(auction.startPrice) + 0.1
      : parseFloat(auction.maxBid) + 0.1
  );

  async function placeBid(amount) {
    await checkMetaMask();

    //get auction address and start interacting with it
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const AuctionContract = new ethers.Contract(
      auctionAddress,
      AuctionABI,
      provider
    );
    let signer = provider.getSigner();
    const signedAuctionContract = AuctionContract.connect(signer);
    console.log(signedAuctionContract);
    try {
      const { hash } = await signedAuctionContract.placeBid(
        //change default amount to pab
        {
          value: ethers.utils.parseEther(amount.toString()),
        }
      );

      confirmAlert({
        message: "Transaction pending... 💲",

        buttons: [],
      });
      await provider.waitForTransaction(hash); // Wait till the transaction is mined
      confirmAlert({
        message: `Your Order has been processed seccessfully.`,
        buttons: [
          {
            label: "go back 🔙",
            onClick: () => {
              window.location.reload();
            },
          },
        ],
      });
    } catch (error) {
      console.log(error);
      confirmAlert({
        title: "Request rejected",
        message: `error : the bid has to be higher`,
        buttons: [
          {
            label: "OK!",
            onClick: () => {},
          },
        ],
      });
    }
  }

  async function cancelAuction() {
    await checkMetaMask();
    //get auction address and start interacting with it
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const AuctionContract = new ethers.Contract(
      auctionAddress,
      AuctionABI,
      provider
    );
    let signer = provider.getSigner();
    const signedAuctionContract = AuctionContract.connect(signer);

    try {
      const { hash } = await signedAuctionContract.cancelAuction();

      confirmAlert({
        message: "Transaction pending... 💲",
        buttons: [],
      });
      await provider.waitForTransaction(hash); // Wait till the transaction is mined
      confirmAlert({
        message: `The Auction was cancelled.`,
        buttons: [
          {
            label: "go back 🔙",
            onClick: () => {
              window.location.reload();
            },
          },
        ],
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function withdrawToken() {
    await checkMetaMask();
    //get auction address and start interacting with it
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const AuctionContract = new ethers.Contract(
      auctionAddress,
      AuctionABI,
      provider
    );
    let signer = provider.getSigner();
    const signedAuctionContract = AuctionContract.connect(signer);

    try {
      signedAuctionContract.withdrawToken();
    } catch (error) {
      confirmAlert({
        title: "Request rejected",
        message: error.message,
        buttons: [
          {
            label: "OK!",
            onClick: () => {},
          },
        ],
      });
    }
  }
  async function withdrawFunds() {
    await checkMetaMask();
    //get auction address and start interacting with it
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const AuctionContract = new ethers.Contract(
      auctionAddress,
      AuctionABI,
      provider
    );
    let signer = provider.getSigner();
    const signedAuctionContract = AuctionContract.connect(signer);

    try {
      signedAuctionContract.withdrawFunds();
    } catch (error) {
      confirmAlert({
        title: "Request rejected",
        message: error.message,
        buttons: [
          {
            label: "OK!",
            onClick: () => {},
          },
        ],
      });
    }
  }

  return (
    <>
      <Card
        className="shadow  bg-white "
        style={{
          color: "inherit",
          textDecoration: "inherit",
          marginTop: 20,
        }}
      >
        <Card.Body>
          <Card.Title>Auction</Card.Title>

          <Card.Text style={{ fontSize: 14 }}>
            Highest Bid: {auction.maxBid}
            <br />
            Hihest Bidder:{" "}
            <Link
              style={{ textDecoration: "inherit" }}
              to={`/address/${auction.maxBidder}`}
            >
              {auction.maxBidder.substring(0, 16)}....
            </Link>
          </Card.Text>
          <Card.Text style={{ fontSize: 14 }}>
            Owner:
            <Link
              style={{ textDecoration: "inherit" }}
              to={`/address/${auction.owner}`}
            >
              {" "}
              {auction.owner.substring(0, 16)}....
            </Link>
          </Card.Text>
          <Card.Text style={{ fontSize: 14 }}>
            Start Time: {timeConverter(auction.startTime)}
            <br />
            End Time: {timeConverter(auction.endTime)}
          </Card.Text>
          <Card.Text style={{ fontSize: 14 }}>
            Start Price: {auction.startPrice} AVAX
          </Card.Text>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                {auction.bidderAddresses.length >= 1 ? (
                  <th colSpan={2}>Bidders </th>
                ) : (
                  <th colSpan={2}>This Auction has no bidders </th>
                )}
              </tr>
            </thead>
            <tbody>
              {auction.bidderAddresses.map((address, index) => (
                <>
                  <tr key={index}>
                    <td>
                      <Link
                        style={{ textDecoration: "inherit" }}
                        to={`/address/${auction.bidderAddresses[index]}`}
                      >
                        {" "}
                        {auction.bidderAddresses[index].substring(0, 16)}....
                      </Link>
                    </td>
                    <td>{auction.bidderAmounts[index]}</td>
                  </tr>
                </>
              ))}
            </tbody>
          </Table>

          <Card.Text style={{ fontSize: 14 }}>
            This Auction{" "}
            {state[auction.auctionState] === "OPEN" ? (
              <span>
                is <strong>OPEN</strong>
              </span>
            ) : (
              <span>
                was <strong>{state[auction.auctionState]}</strong>.
              </span>
            )}
          </Card.Text>

          {/* {(window.ethereum && state[auction.auctionState]==='OPEN')?null:null} */}

          {window.ethereum && state[auction.auctionState] === "OPEN" ? (
            //whne user have metamask
            <>
              {/* <p style={{ color: "red", fontSize: 12 }}>
                Any new Bid should be 0.01 AVAX higher than the highest Bid
              </p> */}

              {auction.owner === userAddress ? null : (
                <>
                  {/* placeBid */}
                  <Container>
                    <Row className="justify-content-md-center">
                      <Col md="8">
                        <input
                          style={{ height: "2.3rem" }}
                          className="form-control"
                          type="range"
                          step={parseFloat(auction.minIncrement)}
                          min={
                            parseFloat(auction.maxBid) === 0
                              ? parseFloat(auction.startPrice) + 0.01
                              : parseFloat(auction.maxBid) + 0.01
                          }
                          max={parseFloat(auction.directBuy)}
                          placeholder="in AVAX"
                          value={Bid}
                          onChange={(e) => setBid(e.target.value)}
                        />
                      </Col>
                      <Col>
                        <Button
                          style={{ width: "100%" }}
                          variant="primary"
                          onClick={(e) => placeBid(parseFloat(Bid))}
                        >
                          Bid!
                        </Button>
                      </Col>
                      <Row>
                        <Col style={{ fontWeight: "bold" }}>
                          {" "}
                          <br />
                          <center>Bid Amount : {Bid} AVAX</center>
                        </Col>
                      </Row>
                    </Row>

                    <br />

                    <Row>
                      <Col>
                        {/* buy directly */}
                        <Button
                          style={{ width: "100%" }}
                          variant="secondary"
                          onClick={(e) => {
                            placeBid(parseFloat(auction.directBuy));
                          }}
                        >
                          buy it directly for {auction.directBuy} AVAX
                        </Button>
                      </Col>
                    </Row>
                  </Container>

                  <br />
                  <br />
                </>
              )}

              {/* CancelAuction */}
              {auction.isAdmin && auction.bidderAddresses.length === 0 ? (
                //user is the owner of the auction
                <>
                  <Button
                    variant="danger"
                    onClick={(e) => {
                      cancelAuction();
                    }}
                  >
                    Cancel auction
                  </Button>
                  <br />
                  <br />
                </>
              ) : null}
            </>
          ) : (
            <>
              {userAddress === auction.maxBidder ? (
                <>
                  {/* withdrawToken */}
                  {auction.bidderAmounts.length === 0 ? null : (
                    <>
                      <Button
                        variant="primary"
                        onClick={(e) => {
                          withdrawToken();
                        }}
                      >
                        withdraw NFT
                      </Button>
                      <br />
                      <br />
                    </>
                  )}
                </>
              ) : null}

              {userAddress === auction.owner ? (
                <>
                  {/* withdrawFunds */}
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      withdrawFunds();
                    }}
                  >
                    withdraw funds
                  </Button>
                  <br />
                  <br />
                </>
              ) : null}

              <hr />
              <h6 style={{ color: "grey" }}>
                The Auction was Ended or You need to connect your crypto wallet
                to interact with it
              </h6>
            </>
          )}
        </Card.Body>
      </Card>
      <br />
      <br />
    </>
  );
}
