import "./styles.css";
import {
  MyNFTContract,
  NFTContractAbi,
  MyAuctionManagerContract,
  AuctionManagerABI,
} from "../services/backenddata";
import { ethers } from "ethers";
import { Button, Form, Card, Container, Row, Col } from "react-bootstrap";
import { useState } from "react";
export default function AuctionDialog({
  setShowAddressInput,
  confirmAlert,
  URLaddress,
  itemId,
}) {
  const [endtime, setendtime] = useState(null);
  // const [minIncrement, setminIncrement] = useState(null);
  const minIncrement = 0.01;
  const [directBuyPrice, setdirectBuyPrice] = useState(null);
  const [startPrice, setstartPrice] = useState(2);

  async function approve(owner, operator, tokenid) {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const NFTContract = new ethers.Contract(
        MyNFTContract,
        NFTContractAbi,
        provider
      );
      let signer = provider.getSigner();

      const signedNFTContract = NFTContract.connect(signer);

      const { hash } = await signedNFTContract.approve(operator, tokenid);
      console.log(hash);

      confirmAlert({
        message: "Transaction pending...",

        buttons: [],
      });
      await provider.waitForTransaction(hash); // Wait till the transaction is mined
    } catch (error) {
      console.log(error);
      confirmAlert({
        title: "Failed ! 😩",
        buttons: [
          {
            label: "Go back.!",
            onClick: () => {},
          },
        ],
      });
    }
  }

  async function createAuction(owner, operator, tokenid) {
    // _endTime,_minIncrement,_directBuyPrice,_startPrice,_nftAddress,_tokenId
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      // const NFTContract = new ethers.Contract(
      //   MyNFTContract,
      //   NFTContractAbi,
      //   provider
      // );

      const AuctionManagerContract = new ethers.Contract(
        MyAuctionManagerContract,
        AuctionManagerABI,
        provider
      );
      let signer = provider.getSigner();

      const signedAuctionManagerContract =
        AuctionManagerContract.connect(signer);

      const { hash } = await signedAuctionManagerContract.createAuction(
        parseInt(endtime) * 60 * 60, //_endTime - Converting minutes to seconds    -60 min
        ethers.utils.parseEther(minIncrement.toString()), //min incremet  -- 1 avax
        ethers.utils.parseEther(directBuyPrice.toString()), //direct buy --- 10
        ethers.utils.parseEther(startPrice.toString()), // startprice - 6
        MyNFTContract,
        tokenid
      );

      confirmAlert({
        message: "Transaction pending...",

        buttons: [],
      });
      await provider.waitForTransaction(hash); // Wait till the transaction is mined
      confirmAlert({
        message: "done !",

        buttons: [
          {
            label: "Go back.!",
            onClick: async () => {
              setShowAddressInput(false);
              await new Promise((r) => setTimeout(r, 100));
              window.location.reload();
            },
          },
        ],
      });
    } catch (error) {
      console.log(error);
      confirmAlert({
        title: "Failed ! 😩",
        buttons: [
          {
            label: "Go back.!",
            onClick: () => {
              setShowAddressInput(false);
            },
          },
        ],
      });
    }
  }

  async function handleSubmit() {
    if (endtime <= 1) {
      confirmAlert({
        title: "Mistake 🤦",
        message: "The End time is not correct",
        buttons: [
          {
            label: "OK!",
            onClick: () => {},
          },
        ],
      });
    } else if (minIncrement <= 0) {
      confirmAlert({
        title: "Mistake 🤦",
        message: "The Minimal Increment is not correct",
        buttons: [
          {
            label: "OK!",
            onClick: () => {},
          },
        ],
      });
    } else if (directBuyPrice <= 0) {
      confirmAlert({
        title: "Mistake 🤦",
        message: "The Direct Buy Price is not correct",
        buttons: [
          {
            label: "OK!",
            onClick: () => {},
          },
        ],
      });
    } else if (startPrice <= 0) {
      confirmAlert({
        title: "Mistake 🤦",
        message: "The Start Price is not correct",
        buttons: [
          {
            label: "OK!",
            onClick: () => {},
          },
        ],
      });
    } else if (parseFloat(directBuyPrice) < parseFloat(startPrice)) {
      confirmAlert({
        title: "Mistake 🤦",
        message: "The Direct Buy Price has to be higher than Start Price",
        buttons: [
          {
            label: "OK!",
            onClick: () => {},
          },
        ],
      });
    } else {
      await approve(URLaddress, MyAuctionManagerContract, itemId);

      confirmAlert({
        title: "",
        message: "Please approve the tx.",
        buttons: [],
      });

      await createAuction(URLaddress, MyAuctionManagerContract, itemId);
    }
  }

  return (
    <>
      <div id="overlay">
        <Card
          style={{
            position: "absolulte",
            top: "13%",
            margin: " 0 auto",
            width: "30rem",
          }}
        >
          <Card.Body>
            <Card.Subtitle className="mb-2 text-muted">
              During the Auction, the NFT is not yours 😩 You can cancel the
              auction only if there are no bids on it. The End Time is
              important. You can not withdaw the token or the funds until the
              auction is ended or the item gets bought directly.
            </Card.Subtitle>
            <Form onSubmit={(e) => e.preventDefault()}>
              <Form.Group className="mb-0" controlId="Endtime">
                <Form.Label>End Time</Form.Label>
                <Form.Control
                  type="number"
                  min={1}
                  onChange={(e) => setendtime(e.target.value)}
                  value={endtime}
                  placeholder="24"
                  required
                />
                <Form.Text className="text-muted">
                  in hours (more than 1 hour)
                </Form.Text>
              </Form.Group>
              <Form.Group className="mb-0" controlId="directBuyPrice">
                <Form.Label>
                  Direct Buy Price (At which price are you willing to exhcange
                  your NFT directly)
                </Form.Label>
                <Form.Control
                  type="number"
                  onChange={(e) => setdirectBuyPrice(e.target.value)}
                  value={directBuyPrice}
                  placeholder="18"
                  required
                />
                <Form.Text className="text-muted">in AVAX</Form.Text>
              </Form.Group>

              <Form.Group className="mb-0" controlId="startPrice">
                <Form.Label>
                  Start Price (What is the least price you are willing to accept
                  for the NFT)
                </Form.Label>
                <Form.Control
                  type="number"
                  onChange={(e) => setstartPrice(e.target.value)}
                  value={startPrice}
                  placeholder="3"
                  required
                />
                <Form.Text className="text-muted">in Avax</Form.Text>
                <br />
                <Form.Text className="text-muted">
                  *the new bids must be 0.01 AVAX higher than old bids
                </Form.Text>
              </Form.Group>
              <br />

              <Card.Text>
                <Container>
                  <Row>
                    <Col>
                      <Button
                        onClick={async (e) => {
                          await handleSubmit();
                        }}
                      >
                        Let's go ! 🏄‍♀️
                      </Button>
                    </Col>

                    <Col>
                      <Button
                        variant="secondary"
                        onClick={(e) => {
                          setShowAddressInput(false);
                        }}
                      >
                        Go Back
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Card.Text>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
