import { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import spinner from "../assets/spinner.gif";

import {
  public_rpc_url,
  MyNFTContract,
  NFTContractAbi,
} from "../services/backenddata";
import { ethers } from "ethers";
export default function ItemDisplayNoDetails({ tokenId }) {
  const [Item, setItem] = useState(null);
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    async function mainy() {
      let url = public_rpc_url;
      let provider = new ethers.providers.JsonRpcProvider(url);

      const NFTContract = new ethers.Contract(
        MyNFTContract,
        NFTContractAbi,
        provider
      );

      const tokenURI = await NFTContract.tokenURI(tokenId);
      fetch(
        `https://ipfs.nftrade.com/ipfs/${tokenURI.substring(7)}`,
        null,
        1000
      )
        .then((res) => res.json())
        .then((data) => setItem(data))
        .then((bla) => setisLoading(false))
        .catch((e) => console.log(e));
    }

    mainy();

    return () => {};
  }, [tokenId]);

  return (
    <>
      {isLoading ? (
        <Card.Img
          variant="top"
          src={spinner}
          width="100%"
          style={{ borderBottom: " solid 1px #d3d3d3" }}
        />
      ) : (
        <Card.Img
          variant="top"
          src={Item.image}
          style={{ borderBottom: " solid 1px #d3d3d3" }}
        />
      )}
    </>
  );
}
