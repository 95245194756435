import { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import spinner from "../assets/spinner.gif";
import {
  public_rpc_url,
  MyNFTContract,
  NFTContractAbi,
} from "../services/backenddata";
import { ethers } from "ethers";

export default function NFTDisplay({ tokenId }) {
  const [Item, setItem] = useState(null);
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    async function mainy() {
      let url = public_rpc_url;
      let provider = new ethers.providers.JsonRpcProvider(url);

      const NFTContract = new ethers.Contract(
        MyNFTContract,
        NFTContractAbi,
        provider
      );

      const tokenURI = await NFTContract.tokenURI(tokenId);
      fetch(`https://gateway.pinata.cloud/ipfs/${tokenURI.substring(7)}`)
        .then((res) => res.json())
        .then((data) => setItem(data))
        .then((bla) => setisLoading(false))
        .catch((e) => console.log(e));
    }

    mainy();

    return () => {};
  }, [tokenId]);

  return (
    <>
      <br />
      {isLoading ? (
        <Card
          style={{
            width: "18rem",
            color: "inherit",
            textDecoration: "inherit",
          }}
        >
          <Card.Body>
            <Card.Img variant="top" src={spinner} width={250} height={250} />

            {/* <center>
            <ReactLoading
              type={"spin"}
              color={"grey"}
              height={50}
              width={100}
            />
          </center> */}
            <br />
            <br />
            <Card.Title>******</Card.Title>
          </Card.Body>
        </Card>
      ) : (
        <Card
          style={{
            width: "18rem",
            color: "inherit",
            textDecoration: "inherit",
          }}
          as={Link}
          to={"/id/" + tokenId}
        >
          <Card.Img variant="top" src={Item.image} />
          <Card.Body>
            <Card.Title>{Item.name}</Card.Title>

            <Card.Text>
              {Item.attributes[0].trait_type} : {Item.attributes[0].value}
            </Card.Text>
          </Card.Body>
        </Card>
      )}
    </>
  );
}
