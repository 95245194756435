import SVGBuilder from "../components/svgbuilder";
import createImageDataFromState from "../services/createImageDataFromState.js";
import { useState, useEffect } from "react";
import axios from "axios";
import { ethers } from "ethers";
import { AVALANCHE_TESTNET_PARAMS } from "../services/backenddata";
import checkMetaMask from "../services/checkmetamask";
import { MyNFTContract, NFTContractAbi } from "../services/backenddata";
import RaffleMint from "../components/RaffleMint";
import { confirmAlert } from "react-confirm-alert";
import EmailComponent from "../components/Emailsub";
import MintCointer from "../components/MintCounter";
var svgToImage = require("svg-to-image");
var getContext = require("get-canvas-context");

function Mint() {
  // it gets set to ListOfCircles when MintMe is clicked

  const [svgdata, setsvgdata] = useState([]);
  async function handleConfirm(myCost, svg) {
    if (window.ethereum) {
      confirmAlert({
        title: "Wait a sec !",
        message: "You can already confirm the purchase in your wallet",
        buttons: [],
      });

      try {
        //return list of accounts
        await window.ethereum.request({
          // method: "eth_requestAccounts",
          method: "wallet_addEthereumChain",
          params: [AVALANCHE_TESTNET_PARAMS],
        });
        await window.ethereum.request({
          method: "eth_requestAccounts",
        });
      } catch (error) {
        if (error.code === 4001) {
          // User rejected request
          confirmAlert({
            title: "Request rejected",
            message: "Please accept to connect to the dApp",
            buttons: [
              {
                label: "OK!",
                onClick: () => {},
              },
            ],
          });
        } else {
          confirmAlert({
            title: "Request rejected",
            message: "Please accept to connect to the dApp",
            buttons: [
              {
                label: "OK!",
                onClick: () => {},
              },
            ],
          });
        }
      }

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const Contract = new ethers.Contract(
        MyNFTContract,
        NFTContractAbi,
        provider
      );
      const signedContract = Contract.connect(signer);

      try {
        const { hash } = await signedContract.mintItem({
          value: ethers.utils.parseEther(myCost),
        });

        const to_uplaod = {
          address: await signer.getAddress(),
          metadata: svg,
          txhash: hash
        };


        confirmAlert({
          closeOnEscape: false,
          closeOnClickOutside: false,
          title: "Confirming...",
          message: "The NFT will be in your wallet in a few seconds.",
          buttons: [],
        });

        

        await axios.post("https://api.circles.pictures/mint", to_uplaod);

        confirmAlert({
          title: "Congratulations...",
          message: "The NFT is yours 🎉",

          buttons: [
            {
              label: "go back.",
              onClick: () => {},
            },
          ],
        });
      } catch (error) {
        //no funds

        confirmAlert({
          title: "Something went wrong...",
          message:
            "Your wallet does not have enough funds 😭 Or you rejected the metamask request 🔴",

          buttons: [
            {
              label: "go back.",
              onClick: () => {},
            },
          ],
        });
      }
    } else {
      checkMetaMask();
    }
  }

  // HANDLE MINT IF User does not changing anything
  async function handleMint() {
    //CREATING IMAGE SOURCE"
    // createImageDataFromState : take the state and generater <svg> component
    svgToImage(createImageDataFromState(svgdata), function (err, image) {
      if (err) {
        console.log(err);
      }
      // set up a new Canvas2D
      var context = getContext("2d", {
        width: 500,
        height: 500,
      });
      // draw image to canvas
      context.drawImage(image, 0, 0);
      // const imageSource = JSON.stringify(context.canvas.toDataURL("image/png"));
      const imageSource = context.canvas.toDataURL("image/png");

      //calculate cost
      // flooring is for numbers divisible by 3
      let myCost = Math.round(svgdata.length) - 2;
      myCost = Math.floor(myCost);
      confirmAlert({
        // title:
        title: <img src={imageSource} width="350" alt="greatest NFT" />,
        message: `The NFT costs ${myCost} AVAX 💸`,
        buttons: [
          {
            label: "Let's go 🏄‍♀️",
            onClick: () => handleConfirm(myCost.toString(), svgdata),
          },
          {
            label: "back to mommy",
            onClick: () => {},
          },
        ],
      });
    });
  }

  useEffect(() => {
    // const date = new Date()
    // const mintDate = new Date(1651082400 * 1000);

    const timeToMint = 1651082400 - Math.round(new Date().getTime() / 1000);

    if (timeToMint > 1) {
      confirmAlert({
        customUI: ({ onClose }) => <EmailComponent />,
        closeOnEscape: false,
        closeOnClickOutside: false,
        title: "Coming Soon.",
        message: "The Mint will open on 27th April, ",
        buttons: [],
      });
    }

    return () => {
      confirmAlert({
        customUI: ({ onClose }) => onClose(),
      });
    };
  }, []);

  useEffect(() => {
    async function handleConfirm(myCost, svg) {
      if (window.ethereum) {
        confirmAlert({
          title: "Wait a sec !",
          message: "You can already confirm the purchase in your wallet",
          buttons: [],
        });
  
        try {
          //return list of accounts
          await window.ethereum.request({
            // method: "eth_requestAccounts",
            method: "wallet_addEthereumChain",
            params: [AVALANCHE_TESTNET_PARAMS],
          });
          await window.ethereum.request({
            method: "eth_requestAccounts",
          });
        } catch (error) {
          if (error.code === 4001) {
            // User rejected request
            confirmAlert({
              title: "Request rejected",
              message: "Please accept to connect to the dApp",
              buttons: [
                {
                  label: "OK!",
                  onClick: () => {},
                },
              ],
            });
          } else {
            confirmAlert({
              title: "Request rejected",
              message: "Please accept to connect to the dApp",
              buttons: [
                {
                  label: "OK!",
                  onClick: () => {},
                },
              ],
            });
          }
        }
  
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const Contract = new ethers.Contract(
          MyNFTContract,
          NFTContractAbi,
          provider
        );
        const signedContract = Contract.connect(signer);
  
        try {
          const { hash } = await signedContract.mintItem({
            value: ethers.utils.parseEther(myCost),
          });
  
          const to_uplaod = {
            address: await signer.getAddress(),
            metadata: svg,
            txhash: hash
          };
  
  
          confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,
            title: "Confirming...",
            message: "The NFT will be in your wallet in a few seconds.",
            buttons: [],
          });
  
          
  
          await axios.post("https://api.circles.pictures/mint", to_uplaod);
  
          confirmAlert({
            title: "Congratulations...",
            message: "The NFT is yours 🎉",
  
            buttons: [
              {
                label: "go back.",
                onClick: () => {},
              },
            ],
          });
        } catch (error) {
          //no funds
  
          confirmAlert({
            title: "Something went wrong...",
            message:
              "Your wallet does not have enough funds 😭 Or you rejected the metamask request 🔴",
  
            buttons: [
              {
                label: "go back.",
                onClick: () => {},
              },
            ],
          });
        }
      } else {
        checkMetaMask();
      }
    }
  
    // HANDLE MINT IF User does not changing anything
    async function handleMint() {
      //CREATING IMAGE SOURCE"
      // createImageDataFromState : take the state and generater <svg> component
      svgToImage(createImageDataFromState(svgdata), function (err, image) {
        if (err) {
          console.log(err);
        }
        // set up a new Canvas2D
        var context = getContext("2d", {
          width: 500,
          height: 500,
        });
        // draw image to canvas
        context.drawImage(image, 0, 0);
        // const imageSource = JSON.stringify(context.canvas.toDataURL("image/png"));
        const imageSource = context.canvas.toDataURL("image/png");
  
        //calculate cost
        // flooring is for numbers divisible by 3
        let myCost = Math.round(svgdata.length) - 2;
        myCost = Math.floor(myCost);
        confirmAlert({
          // title:
          title: <img src={imageSource} width="350" alt="greatest NFT" />,
          message: `The NFT costs ${myCost} AVAX 💸`,
          buttons: [
            {
              label: "Let's go 🏄‍♀️",
              onClick: () => handleConfirm(myCost.toString(), svgdata),
            },
            {
              label: "back to mommy",
              onClick: () => {},
            },
          ],
        });
      });
    }
    
    // so it does not start on relaoding the page
    if (svgdata.length !== 0) {
      handleMint();
    }

    return () => {};
  }, [svgdata]);

  return (
    <div style={{ marginTop: "90px" }}>
      <RaffleMint />
      <br />
      <br />
      <center><MintCointer /></center>

      <br />
      <hr />
      <br />

      <div style={{marginLeft:20,}}>You can make a customizable mint here ↘️</div>
      <div style={{ marginTop: "30px" }}>
        <SVGBuilder mint={handleMint} setsvgdata={setsvgdata} />
      </div>
    </div>
  );
}

export default Mint;
