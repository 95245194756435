import FaqComponent from "../components/FAQ";
import "react-vertical-timeline-component/style.min.css";
import { Container } from "react-bootstrap";
export default function FAQroute() {
  return (
    <div
      style={{
        marginBottom: 100,
      }}
    >
      <Container fluid style={{ paddingTop: "10vw" }}>
        <FaqComponent />
      </Container>
    </div>
  );
}
