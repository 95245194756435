export default function getRandomColor() {
    let colors = [
      "none",
      "#0C090A",
      "#98AFC7",
      "#000080",
      "#0041C2",
      "#E6E6FA",
      "#25383C",
      "#50C878",
      "#808000",
      "#006400",
      "#00FF00",
      "#F5F5DC",
      "#F0E68C",
      "#FBB117",
      "#7FFD",
      "#FFD700",
      "#3B2F2F",
      "#C04000",
      "#E78A61",
      "#FF0000",
      "#B21807",
      "#550A35",
      "#810541",
      "#872657",
      "#E8ADAA",
      "#FFC0CB",
      "#DC143C",
      "#DA70D6",
      "#915F6D",
      "#6960EC",
      "#5E5A80",
      "#8B008B",
      "#FFF9E3",
      "#FFA500",




      









      "#FF6633",
      "#FFB399",
      "#FF33FF",
      "#FFFF99",
      "#00B3E6",
      "#E6B333",
      "#3366E6",
      "#999966",
      "#99FF99",
      "#B34D4D",
      "#80B300",
      "#809900",
      "#E6B3B3",
      "#6680B3",
      "#66991A",
      "#FF99E6",
      "#CCFF1A",
      "#FF1A66",
      "#E6331A",
      "#33FFCC",
      "#66994D",
      "#B366CC",
      "#4D8000",
      "#B33300",
      "#CC80CC",
      "#66664D",
      "#991AFF",
      "#E666FF",
      "#4DB3FF",
      "#1AB399",
      "#E666B3",
      "#33991A",
      "#CC9999",
      "#B3B31A",
      "#00E680",
      "#4D8066",
      "#809980",
      "#E6FF80",
      "#1AFF33",
      "#999933",
      "#FF3380",
      "#CCCC00",
      "#66E64D",
      "#4D80CC",
      "#9900B3",
      "#E64D66",
      "#4DB380",
      "#FF4D4D",
      "#99E6E6",
      "#6666FF",
    ];
  
  
  
    return colors[Math.floor(Math.random() * colors.length)];
  }
