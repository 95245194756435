import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ethers } from "ethers";
import ReactLoading from "react-loading";
import checkMetaMask from "../services/checkmetamask";
import {
  public_rpc_url,
  AuctionManagerABI,
  MyAuctionManagerContract,
} from "../services/backenddata";
import AuctionCard from "../components/AuctionCard";
import { useParams } from "react-router-dom";

class Auction {
  constructor(
    directBuy,
    owner,
    highestBid,
    tokenIds,
    endTime,
    startPrice,
    auctionState,
    contractAddress
  ) {
    this.directBuy = directBuy;
    this.owner = owner;
    this.highestBid = highestBid;
    this.tokenIds = tokenIds;
    this.endTime = endTime;
    this.startPrice = startPrice;
    this.auctionState = auctionState;
    this.contractAddress = contractAddress;
  }
}

function ShowAuctionsOfAddress() {
  let {URLaddress} = useParams();
  const [Auctions, setAuctions] = useState([]);
  const [isLoading, setisLoading] = useState(true);


  useEffect( () => {
    async function loadDataFromRPCUndUpdateState() {
      let url = public_rpc_url;
      let provider = new ethers.providers.JsonRpcProvider(url);
      const AuctionManagerContract = new ethers.Contract(
        MyAuctionManagerContract,
        AuctionManagerABI,
        provider
      );
  
      let myIds = await AuctionManagerContract.getMyAuctions(URLaddress);
  
      let ListOfAuctions = [];
      //  myIds.map( async id => {
      //   let res = await AuctionManagerContract.auctions(id);
      //   ListOfAuctions.push(res);
      //   // return res;
      // });
  
      for(let id of myIds){
        let auctionAddress = await AuctionManagerContract.auctions(id);
        ListOfAuctions.push(auctionAddress);
      }
  
      ListOfAuctions = ListOfAuctions.reverse();
  
  
      const AuctionsInfo = await AuctionManagerContract.getAuctionInfo(
        ListOfAuctions
      );
  
      let myList = [];
      for (let n = 0; n < ListOfAuctions.length; n++) {
        let auction = new Auction(
          ethers.utils.formatEther(AuctionsInfo[0][n]),
          AuctionsInfo[1][n],
          ethers.utils.formatEther(AuctionsInfo[2][n]),
          ethers.utils.formatEther(AuctionsInfo[3][n]) * 10 ** 18,
          ethers.utils.formatEther(AuctionsInfo[4][n]) * 10 ** 18,
          ethers.utils.formatEther(AuctionsInfo[5][n]),
          ethers.utils.formatEther(AuctionsInfo[6][n]) * 10 ** 18,
          ListOfAuctions[n]
        );
        myList.push(auction);
      }
      setAuctions(myList);
  
      setisLoading(false);
    }
  
    async function loadDataFromMetaUndUpdateState() {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const AuctionManagerContract = new ethers.Contract(
        MyAuctionManagerContract,
        AuctionManagerABI,
        provider
      );
  
      const myIds = await AuctionManagerContract.getMyAuctions(URLaddress);
  
      let ListOfAuctions = [];
  
      for(let id of myIds){
  
        let auctionAddress = await AuctionManagerContract.auctions(id);
        ListOfAuctions.push(auctionAddress);
      }
  
  
  
  
      const AuctionsInfo = await AuctionManagerContract.getAuctionInfo(
        ListOfAuctions
      );
  
  
      let myList = [];
      for (let n = 0; n < ListOfAuctions.length; n++) {
        let auction = new Auction(
          ethers.utils.formatEther(AuctionsInfo[0][n]),
          AuctionsInfo[1][n],
          ethers.utils.formatEther(AuctionsInfo[2][n]),
          ethers.utils.formatEther(AuctionsInfo[3][n]) * 10 ** 18,
          ethers.utils.formatEther(AuctionsInfo[4][n]) * 10 ** 18,
          ethers.utils.formatEther(AuctionsInfo[5][n]),
          ethers.utils.formatEther(AuctionsInfo[6][n]) * 10 ** 18,
          ListOfAuctions[n]
        );
        myList.push(auction);
      }
      setAuctions(myList);
  
      console.log(myIds.map(id => Math.floor(ethers.utils.formatEther(id)*10**18)))
      console.log(ListOfAuctions)
      console.log(myList)
  
  
      setisLoading(false);
    }
  
    async function mainy() {
          
    if (window.ethereum) {
      await checkMetaMask();
      await loadDataFromMetaUndUpdateState();
    } else {
      await loadDataFromRPCUndUpdateState();
    }

    }

    mainy();

    return () => {};
  }, [URLaddress]);
  return (
    <div style={{ marginTop: "60px" }}>
      {isLoading ? (
        <center>
        <ReactLoading type={"bars"} color={"grey"} height={50} width={100} />
      </center>
      ) : (
        <Container>
          {Auctions.length !== 0 ? (
            <Row xs={2} md={3}>
              {Auctions.map((auction, index) => (
                <Col key={index}>
                  <AuctionCard  auction={auction} />
                </Col>
              ))}
            </Row>
          ) : (
            <center>
              <h4 style= {{position: 'absolute', top: '40%', left :'35%'}}>There are no auctions to show</h4>
            </center>
          )}
        </Container>
      )}
    </div>
  );
}

export default ShowAuctionsOfAddress;
