import ChangeRadiusComponent from "./change_radius_comonent";
import ChangeCoordinateComponent from "./change_coordinates_comonent";
import ChangeFillColorComponent from "./change_fillcolor_component";
import ChangeStrokeComponent from "./chacngestroke";
import ChangeStrokeColorComponent from "./change_strokeColor_component";
import ChangeOpacityComponent from "./change_opacity_comonent";
import IncrementInput from "./increment_input_component";

function EditiorForm({
  setEditedCircleIndex,
  EditedCircleIndex,
  Circle,
  setListOfCircles,
  ListOfCircles,
  setHei,
  Hei,
  Wid,
  setWid,
}) {
  return (
    <form onSubmit={e=>e.preventDefault()} style={{marginTop:-10}}>
      <IncrementInput Circle= {Circle} setListOfCircles={setListOfCircles} ListOfCircles = {ListOfCircles} setEditedCircleIndex= {setEditedCircleIndex}  />
      <br />

      <label className="grey-text mt-25">
        The edited Circle : {EditedCircleIndex + 1}
      </label>
      <br />
      <select
        style={{ maxWidth: "90%" }}
        value={EditedCircleIndex}
        onChange={(e) => setEditedCircleIndex(parseInt(e.target.value))}
      >
        {ListOfCircles.map((circle, index) => {
          if (index + 1 === 1) {
            return (
              <option value={index} key={index}>
                {index + 1}st Circle
              </option>
            );
          } else if (index + 1 === 2) {
            return (
              <option value={index} key={index}>
                {index + 1}nd Circle
              </option>
            );
          } else {
            return (
              <option value={index} key={index}>
                {index + 1}th Circle
              </option>
            );
          }
        })}
      </select>
      <br />
      {/* <InputComponent name="width" state={Wid} setState={setWid} />
      <InputComponent name="height" state={Hei} setState={setHei} /> */}

      <ChangeRadiusComponent
        name={"Circle Radius"}
        ListOfCircles={ListOfCircles}
        setListOfCircles={setListOfCircles}
        EditedCircleIndex={EditedCircleIndex}
      />

      <ChangeCoordinateComponent
        names={["x", "y"]}
        ListOfCircles={ListOfCircles}
        setListOfCircles={setListOfCircles}
        EditedCircleIndex={EditedCircleIndex}
      />

      <ChangeFillColorComponent
        name={"Fill Color"}
        ListOfCircles={ListOfCircles}
        setListOfCircles={setListOfCircles}
        EditedCircleIndex={EditedCircleIndex}
      />
      <br />
      <ChangeStrokeComponent
        name={"Stoke Width"}
        ListOfCircles={ListOfCircles}
        setListOfCircles={setListOfCircles}
        EditedCircleIndex={EditedCircleIndex}
      />
      <ChangeStrokeColorComponent
        name={"Stroke Color"}
        ListOfCircles={ListOfCircles}
        setListOfCircles={setListOfCircles}
        EditedCircleIndex={EditedCircleIndex}
      />
      <br />

      <ChangeOpacityComponent
        name={"Opacity"}
        ListOfCircles={ListOfCircles}
        setListOfCircles={setListOfCircles}
        EditedCircleIndex={EditedCircleIndex}
      />


    </form>
  );
}
export default EditiorForm;
