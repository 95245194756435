import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

export default function RoadRoute() {
  return (
    <div
      style={{
        marginTop: "60px",
        padding: "0px 50px",
        backgroundColor: "lightgrey",
      }}
    >
      <VerticalTimeline layout="1-column-left" lineColor="white">
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="27th March - 27th April"
          iconStyle={{ background: "#6f7df6", color: "#fff" }}
          position="right"
          icon={
            <center>
              <h2>π</h2>
            </center>
          }
        >
          <h3 className="vertical-timeline-element-title">Initial phase</h3>
          {/* <h4 className="vertical-timeline-element-subtitle">
            San Francisco, CA
          </h4> */}
          <p>
            ✅ Idea maturation <br />
            ✅ Whitepaper <br />
            ✅ Community channels opened <br />
            ✅ Website launch <br />
            ✅ Development of the smart contracts for the Marketplace, DAO and
            staking <br />
            ✅ Making the Circles NFT compatible with other secondary markets<br /> ✅ 
            Choosing the launch day <br />
            💬 Twitter and Discord accounts and ambasaadors networking <br />
            💬 coordinating Marketing campaign and community give aways <br />
          </p>
        </VerticalTimelineElement>


        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="27th April - 10 May"
          iconStyle={{ background: "green", color: "#fff" }}
          icon={
            <center>
              <h2>π</h2>
            </center>
          }
        >
          <h3 className="vertical-timeline-element-title">Launch</h3>
          <p>
            💬 Start the mint proccess of 3142 NFTs
            <br />
            💬 Enabling Marketplace functionality <br />
            💬 Listing the NFT Collection on secondary marketplaces <br />
          </p>{" "}
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2nd May"
          iconStyle={{ background: "purple", color: "#fff" }}
          icon={
            <center>
              <h2>π</h2>
            </center>
          }
        >
          <h3 className="vertical-timeline-element-title">Staking</h3>
          <p>
            💬 Deploy the staking contract which creates the PieCoin
            <br />
            💬 Creating the AVAX-PIE trading pair on Trader Joe (the biggest DEX on Avalanche 🔺 and provide 5% of the supply into the pair<br />
            💬 Hold the first vote in the history of Circles DAO
             </p>{" "}
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="Until October 2022 "
          iconStyle={{ background: "pink", color: "#fff" }}
          icon={
            <center>
              <h2>π</h2>
            </center>
          }
        >
          <h3 className="vertical-timeline-element-title">..Relaunch Circles with a general marketplace...</h3>
        </VerticalTimelineElement>

      </VerticalTimeline>
    </div>
  );
}
