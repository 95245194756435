import { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { ethers } from "ethers";
import ReactLoading from "react-loading";
import checkMetaMask from "../services/checkmetamask";
import { Link } from "react-router-dom";
import {
  public_rpc_url,
  AuctionManagerABI,
  MyAuctionManagerContract,
} from "../services/backenddata";
import AuctionCard from "../components/AuctionCard";

class Auction {
  constructor(
    directBuy,
    owner,
    highestBid,
    tokenIds,
    endTime,
    startPrice,
    auctionState,
    contractAddress
  ) {
    this.directBuy = directBuy;
    this.owner = owner;
    this.highestBid = highestBid;
    this.tokenIds = tokenIds;
    this.endTime = endTime;
    this.startPrice = startPrice;
    this.auctionState = auctionState;
    this.contractAddress = contractAddress;
  }
}

function Marketplace() {
  const [Auctions, setAuctions] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  async function loadDataFromMetaUndUpdateState(pro) {
    const provider = pro;

    const AuctionManagerContract = new ethers.Contract(
      MyAuctionManagerContract,
      AuctionManagerABI,
      provider
    );

    let ListOfAuctions = await AuctionManagerContract.getOpenAuctions();
    //filter cancelled
    ListOfAuctions = ListOfAuctions.filter(
      (T, index) => T !== "0x0000000000000000000000000000000000000000"
    );

    console.log(ListOfAuctions);

    const AuctionsInfo = await AuctionManagerContract.getAuctionInfo(
      ListOfAuctions
    );
    console.log(AuctionsInfo);

    let myList = [];
    for (let n = 0; n < ListOfAuctions.length; n++) {
      let auction = new Auction(
        ethers.utils.formatEther(AuctionsInfo[0][n]),
        AuctionsInfo[1][n],
        ethers.utils.formatEther(AuctionsInfo[2][n]),
        ethers.utils.formatEther(AuctionsInfo[3][n]) * 10 ** 18,
        ethers.utils.formatEther(AuctionsInfo[4][n]) * 10 ** 18,
        ethers.utils.formatEther(AuctionsInfo[5][n]),
        ethers.utils.formatEther(AuctionsInfo[6][n]) * 10 ** 18,
        ListOfAuctions[n]
      );
      myList.push(auction);
    }
    setAuctions(myList);

    setisLoading(false);
  }

  useEffect(() => {
    async function mainy() {
      if (window.ethereum) {
        const prov = new ethers.providers.Web3Provider(window.ethereum);
        await checkMetaMask();
        await loadDataFromMetaUndUpdateState(prov);
      } else {
        let url = public_rpc_url;
        let prov = new ethers.providers.JsonRpcProvider(url);
        await loadDataFromMetaUndUpdateState(prov);
      }
    }

    mainy();

    return () => {};
  }, []);
  return (
    <div style={{ marginTop: "60px" }}>
      {isLoading ? (
        <center>
          <ReactLoading type={"bars"} color={"grey"} height={50} width={100} />
        </center>
      ) : (
        <Container>
          <Row>
            <Col>
            <br />
              <Button variant="light" as={Link} to="/fullmarketplace">
                See all Auctions(Including ended ones)
              </Button>
            </Col>
          </Row>
          {Auctions.length !== 0 ? (
            <Row xs={2} md={3}>
              {Auctions.map((auction, index) => (
                <Col>
                  <AuctionCard key={index} auction={auction} />
                </Col>
              ))}
            </Row>
          ) : (
            <center>
              <h4 style={{ position: "absolute", top: "40%", left: "35%" }}>
                There are no auctions to show
              </h4>
            </center>
          )}
        </Container>
      )}
    </div>
  );
}

export default Marketplace;
