//change name to create SVG from State

function createImageDataFromState(ListOfCircles) {
  var intro1 =
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"  width="500px" height="500px"><rect width="500" height="500" style="fill:#f9f1f2;" />';
  var intro2 = "<defs>";
  var intro3 = '<filter id="shadow">';
  var intro4 = '<feDropShadow dx="0" dy="0" stdDeviation="0.6" />';
  var intro5 = "</filter>";
  var intro6 = "</defs>";
  var logo = `<path
  filter="url(#shadow)"
  style=" fill: white; transform: scale(0.35, 0.35) translate(1100px, 1175px);"
  d="M269.6,167.6c4.4-7.6,11.5-7.6,15.9,0l27.4,48.1c4.4,7.6,0.8,13.8-8,13.8h-55.2c-8.7,0-12.3-6.2-8-13.8
L269.6,167.6z M216.6,75c4.4-7.6,11.4-7.6,15.8,0l6.1,11l14.4,25.3c3.5,7.2,3.5,15.7,0,22.9l-48.3,83.7
c-4.4,6.8-11.7,11.1-19.8,11.6h-40.1c-8.8,0-12.4-6.1-8-13.8L216.6,75z"
/>`;
  var tail = "</svg>";
  var body = ListOfCircles.map((circle, index) => {
    var c = `<circle filter="url(#shadow)" cx="${circle.cx}" cy="${circle.cy}" r="${circle.r}" stroke="${circle.stroke}" stroke-width="${circle.strokeWidth}" fill="${circle.fill}" opacity="${circle.opacity}"  />`;
    return c;
  });

  body.push(logo)
  return [intro1, intro2, intro3, intro4, intro5, intro6]
    .concat(body, [tail])
    .join("\n");
}


//#f9f1f2

export default createImageDataFromState;
