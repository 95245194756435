import { Form} from "react-bootstrap";

function ChangeCoordinateComponent({
  names,
  ListOfCircles,
  setListOfCircles,
  EditedCircleIndex,
}) {
  return (
    <>
      <label htmlFor={names[0]} className="grey-text">
        position : {ListOfCircles[EditedCircleIndex].cx},{ListOfCircles[EditedCircleIndex].cy}
      </label>
      <Form.Range
        min={-1000}
        max={1000}
        id={names[0]}
        className="form-control"
        value={ListOfCircles[EditedCircleIndex].cx}
        onChange={(e) =>
          setListOfCircles((prev) =>
            prev.map((circle, index) => {
              if (index === EditedCircleIndex) {
                circle.cx = e.target.value;
              }
              return circle;
            })
          )
        }
      />

{/* 
      <label htmlFor={names[1]} className="grey-text">
        {names[1]}: {ListOfCircles[EditedCircleIndex].cy}
      </label> */}

      <Form.Range
        min={-1000}
        max={1000}
        id={names[1]}
        className="form-control"
        value={ListOfCircles[EditedCircleIndex].cy}
        onChange={(e) =>
          setListOfCircles((prev) =>
            prev.map((circle, index) => {
              if (index === EditedCircleIndex) {
                circle.cy = e.target.value;
              }
              return circle;
            })
          )
        }
      />


    </>
  );
}

export default ChangeCoordinateComponent;
