import { Fragment } from "react";

function DisplayComponent({ ListOfCircles, Hei, Wid }) {
  return (
    <>
      <svg height={Hei} width={Wid} style={{ backgroundColor: "#F9F2F2" }}>
        <defs>
          <filter id="shadow">
            <feDropShadow dx="0" dy="0" stdDeviation="0.6" />
          </filter>
        </defs>
        {ListOfCircles.map((circle, index) => {
          return (
            <Fragment key={index}>
              <circle
                filter="url(#shadow)"
                cx={circle.cx}
                cy={circle.cy}
                r={circle.r}
                stroke={circle.stroke}
                strokeWidth={circle.strokeWidth}
                fill={circle.fill}
                opacity={circle.opacity}
              />
              <path
                filter="url(#shadow)"
                style={{
                  fill: "#FFFFFF",
                  transform: "scale(0.35, 0.35) translate(1100px, 1175px)",
                }}
                d="M269.6,167.6c4.4-7.6,11.5-7.6,15.9,0l27.4,48.1c4.4,7.6,0.8,13.8-8,13.8h-55.2c-8.7,0-12.3-6.2-8-13.8
		L269.6,167.6z M216.6,75c4.4-7.6,11.4-7.6,15.8,0l6.1,11l14.4,25.3c3.5,7.2,3.5,15.7,0,22.9l-48.3,83.7
		c-4.4,6.8-11.7,11.1-19.8,11.6h-40.1c-8.8,0-12.4-6.1-8-13.8L216.6,75z"
              />
            </Fragment>
          );
        })}
      </svg>
    </>
  );
}

export default DisplayComponent;
