function ChangeStrokeComponent({
  name,
  ListOfCircles,
  setListOfCircles,
  EditedCircleIndex,
}) {
  return (
    <>
      <label htmlFor="strokeWidth" className="grey-text">
        {name} : {ListOfCircles[EditedCircleIndex].strokeWidth}
      </label>
      <input
        type="range"
        min={0}
        max={10}
        id="strokeWidth"
        className="form-control"
        value={ListOfCircles[EditedCircleIndex].strokeWidth}
        onChange={(e) =>
          setListOfCircles((prev) =>
            prev.map((circle, index) => {
              if (index === EditedCircleIndex) {
                const newCircle = circle;
                newCircle.strokeWidth = e.target.value;
                return newCircle;
              }
              return circle;
            })
          )
        }
      />
    </>
  );
}

export default ChangeStrokeComponent;
