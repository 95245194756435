import { useState, useEffect, useRef } from "react";
import { ethers } from "ethers";
import {
  Nav,
  Navbar,
  Container,
  Button,
  Tooltip,
  Overlay,
} from "react-bootstrap";
import { Link } from "react-router-dom";

export default function NavBar({ checkMetaMask }) {
  const target = useRef(null);
  const [show, setShow] = useState(false);
  const [SignerAddress, setSignerAddress] = useState(null);
  useEffect(() => {
    async function quickCheck() {
      if (window.ethereum) {
        window.ethereum.request({
          method: "eth_requestAccounts",
        });

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();

        const addr = await signer.getAddress();
         setSignerAddress(addr);
      }
    }

    quickCheck();

    return () => {};
  }, []);
  return (
    <>
      {/* .......... */}
      <Navbar
        expand="lg"
        fixed="top"
        bg="light"
        variant="light"
        className="shadow-sm rounded"
      >
        <Container>
          <Navbar.Brand as={Link} to="/">
            <span style={{ fontSize: 40, position:"absolute", top: 0 }}>Circles.</span>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            className="justify-content-end"
            id="basic-navbar-nav"
          >
            <Nav className="justify-content-end">
              <Nav.Link as={Link} to="/mint">
                Mint
              </Nav.Link>
              <Nav.Link as={Link} to="/Roadmap">
                Roadmap
              </Nav.Link>
              <Nav.Link as={Link} to="/faq">
                FAQ
              </Nav.Link>

              <Nav.Link as={Link} to="/items">
              All NFTs
            </Nav.Link>
              <Nav.Link href="https://circles-nft.gitbook.io/" target="_blank">
                Whitepaper
              </Nav.Link>
              {/* <Nav.Link as={Link} to="/marketplace">
              Marketplace
            </Nav.Link> */}

              {SignerAddress ? (
                <div>
                  <Button
                    // style={{ marginLeft: "4vw" }}
                    variant="outline-dark"
                    ref={target}
                    as={Link}
                    to={`/address/${SignerAddress}`}
                    // onClick={async () => {
                    //   setShow(!show);
                    // }}
                  >
                    {SignerAddress.substring(0, 7)} ...
                  </Button>
                  <Overlay
                    target={target.current}
                    show={show}
                    placement="bottom"
                  >
                    {(props) => (
                      <Tooltip {...props}>
                        <Link
                          style={{
                            textDecoration: "none",
                            color: "white",
                            fontSize: 18,
                          }}
                          to={`/address/${SignerAddress}`}
                          onClick={() => setShow(false)}
                        >
                          My NFTs
                        </Link>
                        <br />
                        <Link
                          style={{
                            textDecoration: "none",
                            color: "white",
                            fontSize: 18,
                          }}
                          to={`/auctionsofaddress/${SignerAddress}`}
                          onClick={() => setShow(false)}
                        >
                          My Auctions
                        </Link>{" "}
                        <br />
                      </Tooltip>
                    )}
                  </Overlay>
                </div>
              ) : (
                <Button
                  // style={{ marginLeft: "4vw" }}
                  variant="outline-dark"
                  onClick={() => checkMetaMask(setSignerAddress)}
                >
                  Connect Wallet
                </Button>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
